import React, { FC, useCallback, useEffect, useState } from 'react';
import { IJoystickUpdateEvent } from 'react-joystick-component/build/lib/Joystick';
import { observer } from 'mobx-react-lite';
import { useMedia } from 'react-use';
import ControlsPanel from '../../components/JoystickPanel';
import Loader from '../../containers/Space/containers/Loader/Loader';
import DebugPanel from '../../components/DebugPanel';
import useAppSnackbar from '../../hooks/useAppSnackBar';
import { useControlsStore, useSpaceStore } from '../../hooks/stores/useSpaceStore';
import classNames from './Space.module.css';
import EnterScreen from './containers/EnterScreen/EnterScreen';
import BootstrapService from '../../../domain/services/Bootstrap.service';
import PoweredOwn from '../../components/PoweredOwn/PoweredOwn';
import { mqMobileMax } from '../../data/variables';

const Space: FC = observer(() => {
  const {
    playerApi,
    setPlayerApi,
    setIsLoading,
    setShowStart,
    updateNetwork,
    setShowEnterScreen,
    setDebugInfo,
    debugInfo,
    switchToNextSpace,
    setBootstrap,
    isLoading,
    settingsCurrentSpace,
    showStart,
    showEnterScreen,
    startSpace,
    setSettingsSpace,
  } = useSpaceStore();

  const { showControls } = useControlsStore();

  const isMobile = useMedia(`(max-width:${mqMobileMax})`, false);
  const { enqueueWarningSnackbar } = useAppSnackbar();
  const showDebugPanel = process.env.REACT_APP_DEBUG_PANEL === 'true';

  const enterHandler = useCallback(() => {
    setShowStart(false);
    setShowEnterScreen(false);
    switchToNextSpace()
      .then(() => updateNetwork())
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const startHandler = useCallback(() => {
    setShowStart(false);
    startSpace();
  }, []);

  useEffect(() => {
    const sceneName = process.env.REACT_APP_SCENE ?? '';
    const bootstrapService = new BootstrapService();
    bootstrapService.registerScenes();
    bootstrapService.createScene(sceneName);

    const background = bootstrapService.scene?.background;
    const title = bootstrapService.scene?.title;
    if (background && title) setSettingsSpace({ background, title });

    bootstrapService.startScene(sceneName, { initSpace: true })
      .then(() => {
        if (!bootstrapService.app || !bootstrapService.playerApiInstance) throw Error('App initialization failed');
        setPlayerApi(bootstrapService.playerApiInstance);
        if (showDebugPanel) {
          bootstrapService.app.networkManager?.transport.clientEvents.on('dc_time_sync', (timeInfo) => {
            setDebugInfo(JSON.stringify(timeInfo, null, 1)
              .replace('{', '')
              .replace('}', '')
              .trim());
          });
        }

        if (bootstrapService.errors) {
          bootstrapService.errors.forEach((error) => {
            enqueueWarningSnackbar(error);
          });
        }

        setBootstrap(bootstrapService);
        setTimeout(() => {
          setShowStart(true);
        }, 10 * (1000 / 60));
      });
    return () => {
      bootstrapService.destroy();
    };
  }, []);

  const joystickMoveHandler = useCallback((event: IJoystickUpdateEvent) => {
    if (event.x !== null && event.y !== null) playerApi?.setMovementDirection(event.x, event.y);
  }, [playerApi]);

  const joystickStopHandler = useCallback(() => {
    playerApi?.setMovementDirection(0, 0);
  }, [playerApi]);

  return (
    <div className="App">
      <ControlsPanel
        show={showControls}
        onJoystickMove={joystickMoveHandler}
        onJoystickStop={joystickStopHandler}
      />
      {isLoading && <div className={classNames.title}>
        {settingsCurrentSpace?.title || 'OWNverse'}
      </div>}
      {!isMobile && <PoweredOwn
        loading={isLoading}
        show={showControls}
      />}
      {(isMobile && !isLoading) && <PoweredOwn
        loading={isLoading}
        show={showControls}
      />}
      <Loader />
      <div
        className={classNames.formContainer}
        style={{ display: showStart || showEnterScreen ? 'block' : 'none' }}
      >
        <EnterScreen
          onEnter={enterHandler}
          onStart={startHandler}
        />
      </div>
      {showDebugPanel && <DebugPanel info={debugInfo} />}
    </div>
  );
});

export default Space;
