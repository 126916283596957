export enum PlacesId {
  Shopify = 'Place_shopify',
  Youtube = 'Place_youtube',
  Artstation = 'Place_artstation',
  Spotify = 'Place_spotify',
  Behance = 'Place_behance',
  SketchFab = 'Place_sketchFab',
  Canva = 'Place_canva',
  Mirror = 'Place_mirror',
  Instagram = 'Place_instagram',
  Pinterest = 'Place_pinterest',
}
