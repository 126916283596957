import SpawnService from '../services/Spawn.service';
import { AbstractSpaceGenerator } from '../services/SpaceZoneGenerator/AbstractSpaceGenerator';
import BaseScene from './BaseScene';
import { Application } from '../../engine/Application';
import ClassroomSpaceGenerator from '../services/SpaceZoneGenerator/ClassroomSpaceGenerator';

export class ClassroomSpaceScene extends BaseScene {
  public getSpaceGeneratorService(app: Application): AbstractSpaceGenerator {
    return new ClassroomSpaceGenerator({ app, spawnService: this.spawnService });
  }

  public getSpawnService(app: Application): SpawnService {
    return new SpawnService({ app });
  }
}
