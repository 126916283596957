import { LightObjectsInterface, LightStateInterface } from '../types';

export const allLightObjects: LightObjectsInterface = [
  'env_day_clouds',
  'env_day_sphere',
];

export const lightStates: LightStateInterface = {
  day: {
    lightMaps: {
      b_accessories: {
        path: 'lightMaps/b_accessories_lm.jpg',
        intensity: 1.2,
        envIntensity: 0,
      },
      g_clouds: {
        path: 'lightMaps/g_clouds_lm.jpg',
        intensity: 1.2,
        envIntensity: 0,
      },
      g_envdet: {
        path: 'lightMaps/g_envdet_lm.jpg',
        intensity: 1.2,
        envIntensity: 0,
      },
      g_fal: {
        path: 'lightMaps/g_fal_lm.jpg',
        intensity: 1.2,
        envIntensity: 0,
      },
      g_grass: {
        path: 'lightMaps/g_grass_lm.jpg',
        intensity: 0.75,
        envIntensity: 0,
      },
      g_pathway: {
        path: 'lightMaps/pathway_lm.jpg',
        intensity: 1.0,
        envIntensity: 0,
      },
      g_island_bottom: {
        path: 'lightMaps/g_island_bottom_lm.jpg',
        intensity: 1.2,
        envIntensity: 0,
      },
      b_walls: {
        path: 'lightMaps/wallroof_lm.jpg',
        intensity: 1.2,
        envIntensity: 0,
      },
      b_rooftop: {
        path: 'lightMaps/wallroof_lm.jpg',
        intensity: 1.2,
        envIntensity: 0,
      },
    },
    lightObjects: [
      'env_day_clouds',
      'env_day_sphere',
    ],
  },
};
