import { ModelsUrlList } from '../types';

export const models: ModelsUrlList = [
  'space/ownverse.glb',
];

export const colliders: ModelsUrlList = [
  'colliders/collider.glb',
];

export const shadowReceiveObjects = [
  'light_concrete',
  'white_stairs',
  'tile',
  'mountain',
  'djset',
  'logos_backed',
  'ownverse',
  'purple_concrete',
  'shopify_objects',
  'shopify_planes_backed',
  'tshirt',
];

export const wireframeObjects = [
  'mira_wire',
];
