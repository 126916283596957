import { VideoBannersType, VideoBannerType } from '../types';

export const videoBanners: VideoBannersType = {
  tv_screen_01: {
    type: VideoBannerType.Presentation,
    autoplay: false,
    distance: 20,
    distanceVolume: 30,
    enableSharing: true,
    videos: [
      {
        title: 'Avatars',
        mp4: 'video/Avatars.mp4',
        previewUrl: 'video/Avatars.png',
      },
    ],
    timeline: [
      {
        name: 'Logo',
        timeStamp: 0.1,
      },
      {
        name: 'Liquid Legends',
        timeStamp: 5.05,
      },

    ],
  },
};
