import { LightObjectsInterface, LightStateInterface } from '../types';

export const allLightObjects: LightObjectsInterface = [
  'env_day_clouds',
  'env_day_sphere',
  'env_night_clouds',
  'env_night_sphere',
];

export const lightStates: LightStateInterface = {
  day: {
    lightMaps: {
      light_concrete: {
        path: 'lightMaps/day/light_concrete_lm.jpg',
        intensity: 1.2,
        envIntensity: 0,
      },
      tile: {
        path: 'lightMaps/day/tile_lm.jpg',
        intensity: 1.2,
        envIntensity: 0,
      },
      white_stairs: {
        path: 'lightMaps/day/white_stairs_lm.jpg',
        intensity: 1.2,
        envIntensity: 0,
      },
    },
    lightObjects: [
      'env_day_clouds',
      'env_day_sphere',
    ],
  },
  night: {
    lightMaps: {
      light_concrete: {
        path: 'lightMaps/night/light_concrete_lm.jpg',
        intensity: 1.2,
        envIntensity: 0,
      },
      tile: {
        path: 'lightMaps/night/tile_lm.jpg',
        intensity: 1.2,
        envIntensity: 0,
      },
      white_stairs: {
        path: 'lightMaps/night/white_stairs_lm.jpg',
        intensity: 1.2,
        envIntensity: 0,
      },
    },
    lightObjects: [
      'env_night_clouds',
      'env_night_sphere',
    ],
  },
};
