import * as Three from 'three';
import { Adaptive } from './Adaptive';

export class WelcomeScreenAdaptive extends Adaptive {
  get isMobile() {
    return this.app && this.elementSize.width < 650;
  }

  get width() {
    return this.isMobile ? 328 : 800;
  }

  get height() {
    return this.isMobile ? 700 : 586 + (this.isVrSupported ? 56 : 0);
  }

  get scale() {
    const base = this.isMobile ? 0.00055 : 0.00045;
    const kfIpad = Adaptive.isTouchDevice() && (this.elementSize.width > this.elementSize.height) ? 1.8 : 1;
    const kfVR = this.isVRSession ? 1.7 : 1;
    return kfVR * kfIpad * (this.isMobile ? this.getPixelRatioScale(base) : base);
  }

  get padding() {
    return this.isMobile ? 16 : 32;
  }

  get space() {
    return this.isMobile ? 14 : 20;
  }

  get countByRows() {
    return this.isMobile ? [2, 3] : undefined;
  }

  get rowHeight() {
    return this.isMobile ? 150 * 2 : 200;
  }

  get rowFontSize() {
    return this.isMobile ? 16 : 22;
  }

  get contentDirection() {
    return this.isMobile ? 'column' : 'row';
  }

  get bottomHeight() {
    return this.isMobile ? 200 : 160;
  }

  get bottomRowHeight() {
    return this.isMobile ? 84 : 140;
  }

  get margin() {
    return this.isMobile ? 30 : 0;
  }

  get rowsContent() {
    const content = !Adaptive.isTouchDevice() ? [
      {
        imageUrl: 'assets/ui/welcome/mouse.png',
        text: 'Use your mouse to look around',
        iconWidth: 48,
      },
      {
        imageUrl: 'assets/ui/welcome/wsda.png',
        text: 'Move around by pressing WASD',
        iconWidth: 78,
      },
      {
        imageUrl: 'assets/ui/welcome/c-button.png',
        text: 'Switch first and third person view',
      },
      {
        imageUrl: 'assets/ui/welcome/shift-button.png',
        text: 'Switch to run',
      },
      {
        imageUrl: 'assets/ui/welcome/q-button.png',
        text: 'Click to dance',
      },
    ] : [
      {
        imageUrl: 'assets/ui/icons/drag.png',
        text: 'Drag your finger around the screen to look around',
        iconWidth: 40,
        iconHeight: 41,
      },
      {
        imageUrl: 'assets/ui/icons/point.png',
        text: 'Navigate around the place by using  control',
        iconWidth: 40,
        iconHeight: 40,
      },
      {
        imageUrl: 'assets/ui/icons/camera-white.png',
        text: 'Switch first and third person view',
        iconWidth: 30,
        iconHeight: 30,
      },
      {
        imageUrl: 'assets/ui/icons/dance-white.png',
        text: 'Click to dance',
        iconWidth: 30,
        iconHeight: 30,
      },
      {
        imageUrl: 'assets/ui/icons/run.png',
        text: 'Switch to run',
        iconWidth: 30,
        iconHeight: 30,
      },
    ];

    if (this.isMobile) {
      content.forEach((row, index) => {
        Object.assign(row, { fontSize: this.rowFontSize });
        if (index < 2) Object.assign(row, { width: 140, height: 108 });
        if (index >= 2) Object.assign(row, { width: 93, height: 104 });
      });
    }
    return content;
  }

  get position() {
    let y = this.isMobile ? 0 : 0.03;
    if (this.isVRSession) y = this.headPositionY;
    return new Three.Vector3(
      this.isMobile ? 0 : 0,
      y,
      -this.z,
    );
  }
}
