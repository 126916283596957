import { Container } from '../Container';
import { TextBlock } from '../TextBlock';
import * as Theme from '../../Theme';
import { MainMenuAdaptive } from '../../adaptive';
import { PlayerButton } from '../Buttons/PlayerButton';
import { ButtonId } from '../../enum/ButtonId';

export type UserRowParams = {
  adaptive: MainMenuAdaptive;
  name: string;
  id: number;
};

export function UserRow(params: UserRowParams) {
  const { width, isHost, buttonSize } = params.adaptive;
  const padding = params.adaptive.padding / 1;
  return Container({
    width: width - padding * 2,
    height: 64,
    justifyContent: 'center',
    // borderWidth: 1,
    // borderColor: '#00FF00',
  }, [
    Container({
      width: width - 2 * padding,
      height: 62,
      padding,
      // hiddenOverflow: true,
      // textAlign: 'left',
      justifyContent: 'space-between',
      contentDirection: 'row',
    }, [
      TextBlock({
        text: {
          content: params.name,
          fontName: Theme.fontMainBold.name,
          letterSpacing: -0.2,
          color: '#FFFFFF',
          fontOpacity: 0.7,
          // hiddenOverflow: true,
        },
        container: {
          justifyContent: 'center',
          textAlign: 'left',
          width: width - 4 * padding - (isHost ? 4 : 3) * (buttonSize + padding / 4),
          height: 62 - 2 * padding,
          borderWidth: 0,
          hiddenOverflow: true,
          // backgroundOpacity: 1,
        },
      }),
      PlayerButton({
        id: `${ButtonId.MicButton}-${params.id}`,
        imageUrl: 'assets/ui/icons/mic.png',
        selectedImg: 'assets/ui/icons/no-mic.png',
        disableImg: 'assets/ui/icons/mic-gray.png',
        width: buttonSize,
        height: buttonSize,
      }),
      PlayerButton({
        id: `${ButtonId.CamButton}-${params.id}`,
        imageUrl: 'assets/ui/icons/cam-white.png',
        selectedImg: 'assets/ui/icons/no-cam-white.png',
        disableImg: 'assets/ui/icons/cam-disabled.png',
        width: buttonSize,
        height: buttonSize,
      }),
      PlayerButton({
        id: `${ButtonId.PinButton}-${params.id}`,
        imageUrl: 'assets/ui/icons/pin.png',
        selectedImg: 'assets/ui/icons/unpin.png',
        width: buttonSize,
        height: buttonSize,
      }),
      isHost ? PlayerButton({
        id: `${ButtonId.DeleteButton}-${params.id}`,
        imageUrl: 'assets/ui/icons/cross.png',
        width: buttonSize,
        height: buttonSize,
      }) : null,
    ]),
    Container({
      width: width - padding * 4,
      height: 2,
      backgroundColor: '#FFFFFF',
      backgroundOpacity: 0.1,
    }, []),
  ]);
}
