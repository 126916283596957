import { VideoBannersType, VideoBannerType } from '../types';

export const videoBanners: VideoBannersType = {
  main_screen: {
    type: VideoBannerType.Presentation,
    autoplay: false,
    distance: 20,
    distanceVolume: 30,
    enableSharing: true,
    videos: [
      {
        title: 'Pitchdeck',
        mp4: 'video/main_screen/Main_Screen_Pitchdeck.mp4',
        previewUrl: 'video/main_screen/Main_Screen_Pitchdeck.png',
      },
    ],
    timeline: [
      {
        name: 'Pitchdeck Cover',
        timeStamp: 0.1,
      },
      {
        name: 'About Us',
        timeStamp: 6,
      },
      {
        name: 'The Problem',
        timeStamp: 11,
      },
      {
        name: 'The Solution',
        timeStamp: 16,
      },
      {
        name: 'Tradesville Ecosystem',
        timeStamp: 21,
      },
      {
        name: 'User Value Proposition',
        timeStamp: 26,
      },
      {
        name: 'Market Opportunity',
        timeStamp: 31,
      },
      {
        name: 'Revenue Streams',
        timeStamp: 36,
      },
      {
        name: 'Competitive Advantage',
        timeStamp: 41,
      },
      {
        name: 'The Competition',
        timeStamp: 46,
      },
      {
        name: 'Market Adoption',
        timeStamp: 51,
      },
      {
        name: 'Traction',
        timeStamp: 56,
      },
      {
        name: 'Fundraising Ask',
        timeStamp: 61,
      },
      {
        name: 'Contact',
        timeStamp: 66,
      },
    ],

  },
  tv_screen_1: {
    type: VideoBannerType.Video,
    autoplay: false,
    distance: 20,
    distanceVolume: 30,
    enableSharing: false,
    videos: [
      {
        title: 'Animation Eposide 1-16',
        mp4: 'video/tv_screen_1/Screen_1_1.mp4',
        previewUrl: 'video/tv_screen_1/Screen_1_1.png',
      },
    ],
  },
  tv_screen_2: {
    type: VideoBannerType.Video,
    autoplay: false,
    distance: 20,
    distanceVolume: 30,
    enableSharing: false,
    videos: [
      {
        title: 'What is money',
        mp4: 'video/tv_screen_2/Screen_2_1.mp4',
        previewUrl: 'video/tv_screen_2/Thumbnails/Screen 2_1.png',
      },
      {
        title: 'Types on income',
        mp4: 'video/tv_screen_2/Screen_2_2.mp4',
        previewUrl: 'video/tv_screen_2/Thumbnails/Screen 2_2.png',
      },
      {
        title: 'Income vs expenses',
        mp4: 'video/tv_screen_2/Screen_2_3.mp4',
        previewUrl: 'video/tv_screen_2/Thumbnails/Screen 2_3.png',
      },
      {
        title: 'Banks',
        mp4: 'video/tv_screen_2/Screen_2_4.mp4',
        previewUrl: 'video/tv_screen_2/Thumbnails/Screen 2_4.png',
      },
      {
        title: 'Investing',
        mp4: 'video/tv_screen_2/Screen_2_5.mp4',
        previewUrl: 'video/tv_screen_2/Thumbnails/Screen 2_5.png',
      },
      {
        title: 'Goals',
        mp4: 'video/tv_screen_2/Screen_2_6.mp4',
        previewUrl: 'video/tv_screen_2/Thumbnails/Screen 2_6.png',
      },
    ],
  },
  tv_screen_3: {
    type: VideoBannerType.Video,
    autoplay: false,
    distance: 20,
    distanceVolume: 30,
    enableSharing: false,
    videos: [
      // {
      //   title: 'Quieres saber los secretos para hacerte rico_  Toilet paper los sabe #educacionfinanciera',
      //   mp4: 'video/tv_screen_3/Screen_3_1.mp4',
      //   ogv: 'video/tv_screen_3/Screen_3_1.mp4',
      //   previewUrl: 'video/tv_screen_3/Thumbnails/Screen 3_1.png',
      // },
      {
        title: '¿Qué es el dinero?',
        mp4: 'video/tv_screen_3/Screen_3_2.mp4',
        previewUrl: 'video/tv_screen_3/Thumbnails/Screen 3_2.png',
      },
      {
        title: 'Tipos de ingresos',
        mp4: 'video/tv_screen_3/Screen_3_3.mp4',
        previewUrl: 'video/tv_screen_3/Thumbnails/Screen 3_3.png',
      },
      {
        title: 'Ingresos vs gastos',
        mp4: 'video/tv_screen_3/Screen_3_4.mp4',
        previewUrl: 'video/tv_screen_3/Thumbnails/Screen 3_4.png',
      },
      {
        title: 'Bancos',
        mp4: 'video/tv_screen_3/Screen_3_5.mp4',
        previewUrl: 'video/tv_screen_3/Thumbnails/Screen 3_5.png',
      },
      {
        title: 'Inversión',
        mp4: 'video/tv_screen_3/Screen_3_6.mp4',
        previewUrl: 'video/tv_screen_3/Thumbnails/Screen 3_6.png',
      },
      {
        title: 'Metas',
        mp4: 'video/tv_screen_3/Screen_3_7.mp4',
        previewUrl: 'video/tv_screen_3/Thumbnails/Screen 3_7.png',
      },
    ],
  },
  tv_screen_4: {
    type: VideoBannerType.Video,
    autoplay: false,
    distance: 20,
    distanceVolume: 30,
    enableSharing: false,
    videos: [
      {
        title: 'Parody',
        mp4: 'video/tv_screen_4/Screen_4_1.mp4',
        previewUrl: 'video/tv_screen_4/Screen_4_1.png',
      },
    ],
  },
};
