import EventEmitter from 'eventemitter3';

import { toArrayBuffer } from '../../shared/helpers/toArrayBuffer';
import {
  MessageOptions,
  NetworkId,
  OnCloseConnection,
  OnJoinedRoomPayload,
  OnLeftRoomPayload,
  OnMessagePayload,
  ProtocolType,
  TimeInfo,
} from './types';
import NetworkObject, { NetworkObjectLifeTimeTypes, NetworkObjectStatus } from './NetworkObject';
import { SyncVariable } from './SyncVariable';
import TransformVariable from './variables/TransformVariable';
import TransportPlutoBinary from './TransportPlutoBinary';
import AnimatorVariable from './variables/AnimatorVariable';
import { WorkerMessage, WorkerMessageTypes } from './messages/WorkerTypes';
import MessagesPool, { MessageData, msgId, ReceiveMessage, SendMessage } from './messages/MessagesPool';
import MessagesWorker from './messages/MesagesWorker';
import ReceiveMessagesPool from './messages/ReceiveMessagesPool';
import SendMessagesPool from './messages/SendMessagesPool';
import SessionStore from './sessionStore/SessionStore';
import { SyncVariableSerialized, VariablePayload } from './payloads/VariablePayload';
import ObjectPayload, { NetworkObjectSerialized } from './payloads/ObjectPayload';
import IDService, { IDType } from './services/ID.service';
import { NetworkEvent } from './NetworkEvent';
import Generated, { engine, google } from '../../generated';
import IMessageData = engine.network.messages.IMessageData;
import MessagesData = engine.network.messages.MessagesData;
import ObjectReceivedPayload = engine.network.payloads.ObjectReceivedPayload;
import RequestForObjectsPayload = engine.network.payloads.RequestForObjectsPayload;

export enum MessageTypes {
  sendOwnedObjects = 'sendOwnedObjects',
  successReceiveNewObject = 'successReceiveNewObject',
  broadcastVariable = 'broadcastVariable',
  broadcastVariables = 'broadcastVariables',
  removeUser = 'removeUser',
  ping = 'Ping',
  broadcastMessagesBatch = 'broadcastMessagesBatch',
  system = 'systemMessage',
  eventRequest = 'eventRequest',
  eventResponse = 'eventResponse',
  requestForObject = 'requestForObjects',
}

export type NetworkManagerEventTypes = {
  onReceiveObjects: () => void;
  onReceiveVariables: () => void;
  onReceiveSystemMessage: ({ message }: { message: ReceiveMessage }) => void;
  onChangeActiveRoom: ({ roomId, clientId }: { roomId: NetworkId; clientId: NetworkId }) => void;
  onEnterSession: () => void;
  onSyncUser: () => void;
  // onSetUser: (user: User[]) => void;

  removeUser: (userId: NetworkId) => void;
  setNewUser: (userName: string, userId: string) => void;
};

export type UserData = {
  id: NetworkId;
  joinedTime: number;
};

// TODO: move all network from engine
export default class NetworkManager {
  public transport: TransportPlutoBinary;

  public receiveMessagesPool: ReceiveMessagesPool;

  public sendMessagesPool: SendMessagesPool;

  public enabled = true;

  public initialized = false;

  protected _events: EventEmitter<NetworkManagerEventTypes> = new EventEmitter<NetworkManagerEventTypes>();

  public networkId: NetworkId;

  public prevNetworkId: NetworkId = -1;

  public roomHost: Record<string, boolean> = {};

  public activeRoomId?: NetworkId = -1;

  public roomIds: Set<NetworkId> = new Set<NetworkId>();

  public messagesWorker: Worker | MessagesWorker;

  public IDService: IDService;

  // public debugRPS = 0;

  public variableTypes: { [key: string]: typeof SyncVariable<any> } = {
    [SyncVariable.type]: SyncVariable,
    [TransformVariable.type]: TransformVariable,
    [AnimatorVariable.type]: AnimatorVariable,
  };

  public objectsTypes: { [key: string]: typeof NetworkObject } = {
    [NetworkObject.type]: NetworkObject,
  };

  public sessionStore: SessionStore;

  // TODO: need object User
  public onlineUsers: Record<NetworkId, UserData[]> = {};

  // TODO: create service objectCollection
  public objects: NetworkObject[] = [];

  constructor(transport: TransportPlutoBinary, sessionStore?: SessionStore) {
    this.transport = transport;
    if (transport.networkId < 0) {
      console.error('No networkId');
    }
    this.receiveMessagesPool = new ReceiveMessagesPool();
    this.sendMessagesPool = new SendMessagesPool();
    this.networkId = transport.networkId;
    this.IDService = new IDService({ networkID: this.networkId });
    this.setupTransport();

//    const publicUrl = __webpack_public_path__;
//    if (process.env.REACT_APP_WORKER_PUBLIC_URL && process.env.NODE_ENV !== 'development') {
//      __webpack_public_path__ = process.env.REACT_APP_WORKER_PUBLIC_URL;
//    }

    this.messagesWorker = new Worker(/* webpackChunkName: "network-worker" */ new URL('./workers/Messages.worker', import.meta.url));

//    if (process.env.REACT_APP_WORKER_PUBLIC_URL && process.env.NODE_ENV !== 'development') {
//      __webpack_public_path__ = publicUrl;
//    }

    // this.messagesWorker = new MessagesWorker();
    this.setupMessagesWorker();
    this.sessionStore = sessionStore ?? new SessionStore();
  }

  public checkId(id: NetworkId | null | undefined): boolean {
    return typeof id !== 'undefined' && id !== null && id >= 0;
  }

  get wsTimeIfo(): TimeInfo {
    return this.transport.wsTimeIfo;
  }

  get dcTimeIfo(): TimeInfo {
    return this.transport.dcTimeIfo;
  }

  getTimeInfo(required = false) {
    return required ? this.wsTimeIfo : this.dcTimeIfo;
  }

  getCorrectTime(required = false, time = Date.now()) {
    const timeInfo = this.getTimeInfo(required);
    return time + timeInfo.offset;
  }

  getShiftedTime(required = false, time = Date.now()) {
    // TODO: we need to minus (client_sent - client_receive)
    const timeInfo = this.getTimeInfo(required);
    return time + timeInfo.offset - timeInfo.rtt;
  }

  public get isSessionHost(): boolean {
    return this.roomHost[this.sessionStore.id];
  }

  public get isActiveRoomHost(): boolean {
    const id = this.activeRoomId ?? this.sessionStore.id;
    return this.roomHost[id];
  }

  public sendSystemMessage(payload: ArrayBuffer) {
    const message = MessagesPool.encodeMessage({
      type: MessageTypes.system,
      payload: new Uint8Array(payload),
    });
    // TODO: replace by manager method
    return this.transport.sendWSMessageTo(message, [this.sessionStore.id]);
  }

  protected receiveRequestForObjects(clientId: NetworkId, roomId: NetworkId) {
    this.sendOwnedObjects([clientId], roomId);
  }

  public sendRequestForObjects(clientId: NetworkId, roomId: NetworkId) {
    const protoPayload = RequestForObjectsPayload.create({
      roomId,
      clientId,
    });
    const proto = MessagesData.create({
      messages: [
        {
          payload: google.protobuf.Any.create({
            type_url: 'requestForObjectsPayload',
            value: RequestForObjectsPayload.encode(protoPayload).finish(),
          }),
        },
      ],
    });
    return this.send({
      type: MessageTypes.requestForObject,
      payload: toArrayBuffer(MessagesData.encode(proto).finish()),
      transportType: ProtocolType.WS,
      required: true,
      roomId,
    });
  }

  public sendEventRequest<EventRequestData>(event: NetworkEvent, requestData: EventRequestData, clients: NetworkId[] = []) {
    const buffer = event.createRequestBuffer(requestData);
    return this.send({
      type: MessageTypes.eventRequest,
      payload: buffer,
      transportType: ProtocolType.WS,
      required: true,
      roomId: this.sessionStore.id,
      clients,
    });
  }

  public sendEventResponse<EventResponseData>(event: NetworkEvent, responseData: EventResponseData, clients: NetworkId[] = []) {
    const buffer = event.createResponseBuffer(responseData);
    return this.send({
      type: MessageTypes.eventResponse,
      payload: buffer,
      transportType: ProtocolType.WS,
      required: true,
      roomId: this.sessionStore.id,
      clients,
    });
  }

  public createOrJointRoom(roomId: NetworkId | null): Promise<{ roomId: NetworkId }> {
    return this.transport.createOrJointRoom(roomId).then(({ roomId: createdRoomId }) => {
      this.registerRoom(createdRoomId);
      return { roomId: createdRoomId };
    });
  }

  public changeRoom(roomId: NetworkId) {
    console.log('changeRoom', this.activeRoomId, roomId, this.isUserOnline(this.networkId, roomId));
    return Promise.resolve()
      // this.leaveActiveRoom()
      // .then(() => (this.activeRoomId ? this.syncUsersInRoom(this.activeRoomId) : Promise.resolve([])))
      .then(() => {
        if (!this.isUserOnline(this.networkId, roomId)) {
          return this.createOrJointRoom(roomId).then(() => {});
        }
        return this.sendRequestForObjects(this.networkId, roomId);
      })
      .then(() => {
        this.events.emit('onChangeActiveRoom', { roomId, clientId: this.networkId });
        this.activeRoomId = roomId;
        return this.sessionStore.onChangeActiveRoom({ roomId, clientId: this.networkId });
      })
      .then(() => this.syncUsersInRoom(roomId))
      .then(() => ({ roomId }));
  }

  public leaveActiveRoom() {
    const roomId = this.activeRoomId;
    console.log('leaveActiveRoom', roomId);
    return roomId && this.sessionStore.id !== roomId
      ? this.transport.leaveRoom(roomId).then(() => {
        this.onLeftRoom({ roomId, connectionId: this.networkId });
        this.objects = this.objects.filter((obj) => !obj.inRoom(roomId) || obj.permanent);
        this.activeRoomId = -1;
      })
      : Promise.resolve().then(() => {
        // delete this.roomHost[this.networkId];
        if (roomId) this.sendObjects(this.removeObjectsByOwner(this.networkId, roomId));
        this.objects = this.objects.filter((obj) => !obj.inRoom(roomId) || obj.permanent);
        this.activeRoomId = -1;
      });
  }

  public registerRoom(roomId: NetworkId) {
    this.activeRoomId = roomId;
    this.roomIds.add(roomId);
  }

  public setActiveRoom(roomId: NetworkId) {
    this.activeRoomId = roomId;
    this.objects.filter((obj) => !obj.isShared && obj.isOwner())
      .forEach((obj) => {
        obj.roomId = roomId;
      });
  }

  // TODO: initialization state
  public finishInitialization() {
    this.initialized = true;
    this.sendOwnedObjects();
  }

  public get events(): EventEmitter<NetworkManagerEventTypes> {
    return this._events;
  }

  public setupMessagesWorker() {
    if (this.messagesWorker instanceof Worker) {
      this.messagesWorker.onmessage = this.onWorkerMessage.bind(this);
    }
    if (this.messagesWorker instanceof MessagesWorker) {
      this.messagesWorker.events.on('onMessage', this.onWorkerMessage, this);
    }
  }

  public onWorkerMessage(e: MessageEvent<WorkerMessage> | WorkerMessage) {
    const message: WorkerMessage = e instanceof MessageEvent<WorkerMessage> ? e.data : e;
    switch (message.type) {
      // case WorkerMessageTypes.tick:
      //   this.sendData(MessageTypes.ping, {});
      //   break;
      case WorkerMessageTypes.processReceiveMessage:
        this.processReceiveMessage(message.data as ReceiveMessage);
        break;
      case WorkerMessageTypes.processSendMessagesBatch:
        this.processSendMessagesBatch(message.data);
        break;
      default:
    }
  }

  public run() {
    this.messagesWorker.postMessage({ type: WorkerMessageTypes.startWorker });
  }

  public stop() {
    this.messagesWorker.postMessage({ type: WorkerMessageTypes.stopWorker });
    this.messagesWorker.terminate();
  }

  public buildNetObject<Type extends NetworkObject>(type: string, build: ((obj: Type) => void) | undefined = undefined): Type {
    const obj = new this.objectsTypes[type](this).initialize() as Type;
    if (build) {
      build(obj);
    }
    this.objects.push(obj);
    // this.sendOwnedObjects();
    return obj;
  }

  public buildSharedObject<Type extends NetworkObject>(
    type: string,
    code: string,
    build: ((obj: Type) => void) | undefined = undefined,
  ): Type {
    const netObj = this.objects.find((obj) => obj.isShared && obj.code === code);
    if (netObj) {
      return netObj as Type;
    }
    return this.buildNetObject<Type>(type, (obj) => {
      obj.code = code;
      obj.lifeTimeType = NetworkObjectLifeTimeTypes.Shared;
      if (build) build(obj);
    });
  }

  public getObjectByCode<Type extends NetworkObject = NetworkObject>(code: string): Type {
    return this.objects.find((obj) => obj.code === code) as Type;
  }

  public receiveVariable(data: SyncVariableSerialized, sendTime: number, receiveTime: number) {
    const object = this.objects.find((obj) => (obj.id === data.netObjectId));
    // TODO: think about it
    if (!object) return;
    let variable = object.getVariableById(data.id);
    // crate new variable
    if (!variable) {
      const variableType = this.variableTypes[data.type];
      variable = new variableType(data.name);
      object.addVariable(variable);
    }
    // variable.deserialize(data);
    variable.saveValueFromNetwork(data, sendTime, receiveTime);
    variable.setNeedUpdateFromNetwork();
    this.events.emit('onReceiveVariables');
  }

  public receiveNetObject(data: NetworkObjectSerialized) {
    let object = this.objects.find((obj) => obj.id === data.id);
    // new object
    let isNewObject = false;
    if (!object && data.status !== NetworkObjectStatus.Removed) {
      if (!this.objectsTypes[data.type]) {
        console.warn(`receive unknown object type: ${data.type}`);
        return;
      }
      object = new this.objectsTypes[data.type](this);
      object.roomId = this.activeRoomId;
      this.objects.push(object);
      isNewObject = true;
    }
    // update object
    if (object) {
      object.deserialize(data);
      // object.roomId = this.activeRoomId;
    }
    // remove object
    if (object && data.status === NetworkObjectStatus.Removed) {
      this.objects = this.objects.filter((obj) => obj.id !== data.id);
      object.remove();
    }

    if (isNewObject && object) {
      this.sendSuccessReceiveNewObject(object);
    }
  }

  public receiveSuccessReceiveNewObject({ netObjectId, clientId }: { netObjectId: IDType; clientId: NetworkId }) {
    const object = this.objects.find((obj) => obj.id === netObjectId);
    if (!object || !object.isOwner()) return;
    // TODO: think about required
    this.broadcastVariables(object.variables, [clientId], true);
  }

  public setupTransport() {
    this.transport.events.on('onJoinedRoom', this.onJoinedRoom, this);
    this.transport.events.on('onLeftRoom', this.onLeftRoom, this);
    this.transport.events.on('onMessage', this.onReceive, this);
    this.transport.events.on('oncloseConnection', this.onCloseConnection, this);
  }

  // one session -> many rooms ?
  public onJoinedRoom({ roomId, clientId }: OnJoinedRoomPayload) {
    console.warn('onJoinedRoom', roomId, clientId);
    this.events.emit('onEnterSession');
    if (clientId === this.networkId) {
      this.events.emit('onChangeActiveRoom', { roomId, clientId: this.networkId });
    }

    // do not process yourself
    // if (clientId === this.networkId || clientId === this.prevNetworkId) {
    //   return;
    // }
    // if (this.currentRoomId && this.currentRoomId !== roomId) {
    //   // TODO: not our room, strange
    //   console.warn('wrong room');
    //   return;
    // }
    // this.currentRoomId = this.currentRoomId || roomId;
    this.syncUsersInRoom(roomId).then((newClients) => {
      if (newClients && newClients.length > 0) this.sendOwnedObjects(newClients, roomId);
    });
  }

  public syncUsersInRoom(roomId: NetworkId) {
    return this.transport.listRoomConnections(roomId).then(({ roomId: resultRoomId, connectionIds }) => {
      if (!this.roomIds.has(resultRoomId)) return;
      const sortedConnectionIds = [...connectionIds].sort((a, b) => {
        return a - b;
      });
      // FIXME: get host from server?
      if ((connectionIds.length === 1 && this.networkId === connectionIds[0]) || sortedConnectionIds[0] === this.networkId) {
        this.roomHost[resultRoomId] = true;
        console.warn('host');
      }
      const usersInRoom = this.sessionStore.usersInRoom(roomId);
      console.log('syncUsersInRoom', usersInRoom);
      if (usersInRoom.length === 1 && usersInRoom[0].id === this.networkId) {
        this.roomHost[resultRoomId] = true;
        console.warn('host');
      }
      const newUsers: NetworkId[] = [];
      connectionIds.forEach((userId) => {
        if (!this.isUserOnline(userId, resultRoomId)) {
          this.setupNewUser(userId, resultRoomId);
          newUsers.push(userId);
        }
      });
      this.onlineUsers[resultRoomId].forEach((user) => {
        if (connectionIds.indexOf(user.id) < 0) {
          this.removeUser(user.id, resultRoomId);
        }
      });
      return newUsers;
    });
  }

  public onCloseConnection({ connectionId }: OnCloseConnection) {
    this.removeUser(connectionId, this.sessionStore.id);
    this.removeUser(connectionId, this.activeRoomId);
  }

  public onLeftRoom({ roomId, connectionId }: OnLeftRoomPayload) {
    if (!this.roomIds.has(roomId)) return;
    if (this.networkId === connectionId || this.prevNetworkId === connectionId) {
      // TODO: reconnect ?
      this.roomIds.delete(roomId);
      this.activeRoomId = -1;
    }
    this.removeUser(connectionId, roomId);
  }

  public removeUser(userId: NetworkId, roomId: NetworkId | null = null) {
    const rId = this.checkId(roomId) ? roomId : this.activeRoomId;
    if (!this.checkId(rId)) return;
    console.warn('removeUser', userId);

    if (this.sessionStore.id === roomId) this.events.emit('removeUser', userId);
    // do not process yourself ??
    // if (userId === this.networkId || userId === this.prevNetworkId) {
    //   return;
    // }
    // TODO: clear variables

    if (this.onlineUsers[Number(rId)]) {
      this.onlineUsers[Number(rId)] = this.onlineUsers[Number(rId)].filter((user) => user.id !== userId);
    }

    this.removeObjectsByOwner(userId, rId);
    // this.sendOwnedObjectsVariables();
  }

  public removeObjectsByOwner(ownerId: NetworkId, roomId: NetworkId | null = null): NetworkObject[] {
    const rId = this.checkId(roomId) ? roomId : this.activeRoomId;
    if (!this.checkId(rId) || typeof rId !== 'number' || !this.onlineUsers[rId]) return [];
    // TODO: reset owner in shared object (by time or by server)
    // TODO: network objects and variables need timing
    const newOwnerId = this.onlineUsers[rId].map((user) => user.id).sort()[0];
    if (newOwnerId) {
      this.objects
        .filter((obj) => obj.inRoom(rId) && obj.isShared && !this.isUserOnline(obj.ownerId, rId))
        .forEach((obj) => {
          obj.setOwner(newOwnerId);
          // obj.reset();
        });
    } else {
      // TODO: no user to set shared objects -- remove?
    }

    const removedObjects: NetworkObject[] = [];
    this.objects.filter((obj) => !obj.isLife(ownerId)).forEach((obj) => {
      removedObjects.push(obj);
      obj.remove();
    });
    this.objects = this.objects.filter((obj) => obj.isLife(ownerId));
    return removedObjects;
    // this.sendOwnedObjects();
  }

  public isUserOnline(userId: NetworkId, roomId: NetworkId | null = null): boolean {
    const id = this.checkId(roomId) ? roomId : this.activeRoomId;
    return typeof id === 'number' && this.checkId(id) && this.onlineUsers[id] && !!this.onlineUsers[id].find((user) => user.id === userId);
  }

  public setupNewUser(userId: NetworkId, roomId: NetworkId | null = null) {
    const id = this.checkId(roomId) ? roomId : this.activeRoomId;
    if (typeof id !== 'number' || !this.checkId(id)) return;
    if (!this.onlineUsers[id]) this.onlineUsers[id] = [];
    this.onlineUsers[id].push({
      id: userId,
      joinedTime: Math.floor(Date.now() / 1000),
    });
  }

  // TODO: method to send only one object
  public sendOwnedObjects(clients: NetworkId[] = [], roomId?: NetworkId) {
    const activeRoomId = roomId ?? this.activeRoomId;
    const objects = this.objects.filter((obj) => obj.inRoom(activeRoomId) && obj.isOwner());
    return this.sendObjects(objects, clients, roomId);
  }

  public sendObjects(objects: NetworkObject[], clients: NetworkId[] = [], roomId?: NetworkId) {
    const activeRoomId = roomId ?? this.activeRoomId;
    if (objects.length) {
      const proto = MessagesData.create({
        messages: objects.map((obj: NetworkObject) => {
          const value = obj.payload.toProto();
          return {
            type: obj.objectType,
            payload: {
              type_url: obj.objectType,
              value,
            },
          };
        }),
      });
      this.send({
        type: MessageTypes.sendOwnedObjects,
        payload: toArrayBuffer(MessagesData.encode(proto).finish()),
        transportType: ProtocolType.WS,
        required: true,
        roomId: activeRoomId,
        clients,
      });
    }
  }

  public sendOwnedObjectsVariables() {
    const variables: SyncVariable[] = this.objects.filter((obj) => obj.inRoom(this.activeRoomId) && obj.isOwner())
      .map((obj) => obj.variables).flat();
    return this.broadcastVariables(variables);
  }

  public sendSuccessReceiveNewObject(obj: NetworkObject) {
    const protoPayload = ObjectReceivedPayload.create({
      objectId: obj.id,
      clientId: this.networkId,
    });
    const proto = engine.network.messages.MessageData.create({
      payload: google.protobuf.Any.create({
        type_url: obj.objectType,
        value: ObjectReceivedPayload.encode(protoPayload).finish(),
      }),
    });
    this.send({
      type: MessageTypes.successReceiveNewObject,
      payload: toArrayBuffer(engine.network.messages.MessageData.encode(proto).finish()),
      required: true,
      roomId: obj.roomId,
      transportType: ProtocolType.WS,
      clients: [
        obj.ownerId,
      ],
    });
  }

  public broadcastVariable(variable: SyncVariable) {
    if (!this.initialized) return;
    const type = variable.name;
    const proto = engine.network.messages.MessageData.create({
      payload: {
        type_url: type,
        value: variable.payload.toProto(),
      },
    });
    return this.send({
      type: MessageTypes.broadcastVariable,
      payload: toArrayBuffer(engine.network.messages.MessageData.encode(proto).finish()),
      required: variable.required,
      queueId: variable.increment || 0,
      transportType: variable.required ? ProtocolType.WS : ProtocolType.DC,
      roomId: variable.roomId,
    });
  }

  public broadcastVariables<T>(variables: SyncVariable<T>[], clients: NetworkId[] = [], required?: boolean) {
    if (!this.initialized) return;
    const requiredMessage = typeof required !== 'undefined' ? required : variables.some((vr) => vr.required);
    const roomId = variables.length > 0 ? variables[0].roomId : undefined;
    const proto = MessagesData.create({
      messages: variables.map((variable) => {
        const type = (variable.constructor as any)?.type;
        const value = variable.payload.toProto();
        return {
          type,
          payload: {
            type_url: type,
            value,
          },
        };
      }),
    });
    return this.send({
      type: MessageTypes.broadcastVariables,
      payload: toArrayBuffer(MessagesData.encode(proto).finish()),
      required: requiredMessage,
      transportType: requiredMessage ? ProtocolType.WS : ProtocolType.DC,
      clients,
      roomId,
    });
  }

  public sendRemoveUser(id: NetworkId) {
    const msg = engine.network.messages.UserIdMessage.create({ id });
    this.send({
      type: MessageTypes.removeUser,
      payload: toArrayBuffer(engine.network.messages.UserIdMessage.encode(msg).finish()),
      required: true,
      transportType: ProtocolType.WS,
      roomId: this.sessionStore.id,
    });
  }

  public receiveRemoveUser(userId: NetworkId) {
    if (this.networkId === userId && this.checkId(this.activeRoomId)) {
      this.transport.closeSession(this.activeRoomId ?? -1, false).then(() => {
        // this.objects.forEach((obj) => obj.remove());
      });
      this.objects.forEach((obj) => obj.remove());
      this.objects = [];
    }
  }

  // TODO: rename to sendToPull
  public send(input: Omit<SendMessage, 'id' | 'clientSentTimestamp'>) {
    if (!this.enabled) return;
    const message: SendMessage = {
      id: this.IDService.getGlobalId(),
      clientSentTimestamp: BigInt(Date.now()),
      ...input,
    };
    const workerMessage = { ...message };
    // don't send payload to worker
    workerMessage.payload = undefined;
    this.sendMessagesPool.addMessage(message);
    this.messagesWorker.postMessage({ type: WorkerMessageTypes.processSendMessage, data: workerMessage });
  }

  public onReceive({ message: payload, header, protocol }: OnMessagePayload) {
    const messageData = MessagesPool.decodeMessage(payload);
    const message: ReceiveMessage = {
      header,
      type: messageData.type,
      payload: messageData.payload,
      clientReceivedTimestamp: BigInt(Date.now()),
      required: protocol === ProtocolType.WS,
    };
    if (!message.type) return;

    // TODO: WTF?? may be we need normal events system
    switch (message.type) {
      case MessageTypes.system:
        this.events.emit('onReceiveSystemMessage', { message });
        return;
      default:
        break;
    }

    // save message with payload in main thread
    this.receiveMessagesPool.receiveMessage(message);
    // this.reconnectIfLags(message);
    const workerMessage = { ...message };
    // remove payload for worker
    workerMessage.payload = undefined;
    this.messagesWorker.postMessage({ type: WorkerMessageTypes.receiveMessage, data: workerMessage });
  }

  public processReceiveMessage(message: ReceiveMessage) {
    if (!message.payload) {
      const messageFromPool = this.receiveMessagesPool.getById(msgId(message));
      message.payload = messageFromPool?.payload;
    }
    if (!message.type || !message.payload) return;

    this.receiveMessagesPool.removeById(msgId(message));
    switch (message.type) {
      case MessageTypes.requestForObject: {
        const messages = MessagesData.decode(message.payload);
        messages.messages.forEach((msg: IMessageData) => {
          if (!msg.payload?.value) {
            console.warn('Skip processReceiveMessage requestForObject: no payload', msg);
            return;
          }
          const messagePayload = RequestForObjectsPayload.decode(msg.payload.value);
          this.receiveRequestForObjects(messagePayload.clientId, messagePayload.roomId);
        });
        break;
      }
      case MessageTypes.sendOwnedObjects: {
        const messages = MessagesData.decode(message.payload);
        messages.messages.forEach((msg: IMessageData) => {
          if (!msg.payload?.value) return;
          const variable = this.getObjectPayloadType(msg.payload.type_url as string);
          if (variable) {
            this.receiveNetObject(variable.fromProto(msg.payload.value));
          } else {
            console.warn(`Failed to handle received message of type ${MessageTypes.sendOwnedObjects}`, msg);
          }
        });
        // todo: swypse: if none messages were decoded successfully should we emit?
        this.events.emit('onReceiveObjects');
        break;
      }
      case MessageTypes.successReceiveNewObject: {
        const messageData = engine.network.messages.MessageData.decode(message.payload);
        if (!messageData.payload?.value) {
          console.warn('Skip processReceiveMessage successReceiveNewObject: no payload', messageData);
          return;
        }
        const { objectId, clientId } = ObjectReceivedPayload.decode(messageData.payload.value);
        this.receiveSuccessReceiveNewObject({ netObjectId: objectId, clientId });
        break;
      }
      case MessageTypes.broadcastVariable: {
        const messageData = engine.network.messages.MessageData.decode(message.payload);
        this.onBroadcastVariable(messageData, message);
        break;
      }
      case MessageTypes.broadcastVariables: {
        const { messages } = MessagesData.decode(message.payload);
        messages.forEach((messageData) => this.onBroadcastVariable(messageData, message));
        break;
      }
      case MessageTypes.eventRequest: {
        const networkEvent = Generated.engine.network.NetworkEvent.decode(message.payload);
        const netObject = this.objects.find((obj) => obj.id === networkEvent.objectId);
        if (netObject && netObject.eventDispatcher.hasEvent(networkEvent.eventName)) {
          const event = netObject.eventDispatcher.getEvent(networkEvent.eventName);
          const request = event.parseRequestBuffer(message.payload);
          netObject.eventDispatcher.receiveRequest<typeof request>(event, request, message.header.senderId);
        }
        break;
      }
      case MessageTypes.eventResponse: {
        const networkEvent = Generated.engine.network.NetworkEvent.decode(message.payload);
        const netObject = this.objects.find((obj) => obj.id === networkEvent.objectId);
        if (netObject && netObject.eventDispatcher.hasEvent(networkEvent.eventName)) {
          const event = netObject.eventDispatcher.getEvent(networkEvent.eventName);
          const response = event.parseResponseBuffer(message.payload);
          netObject.eventDispatcher.receiveResponse<typeof response>(event, response);
        }
        break;
      }
      case MessageTypes.removeUser: {
        const userId = engine.network.messages.UserIdMessage.decode(message.payload);
        this.receiveRemoveUser(userId.id);
        break;
      }
      // case MessageTypes.broadcastMessagesBatch:
      //   data.payload.forEach((ms: Message) => {
      //     ms.serverSendTime = message.serverSendTime;
      //     this.onReceive({ message: ms, serverSendTime: message.serverSendTime, sender: '' });
      //   });
      //   break;
      default:
        console.warn(`Network: unknown message type ${message.type}`);
    }
  }

  protected onBroadcastVariable(messageData: IMessageData, message: ReceiveMessage) {
    if (!messageData.payload?.value) {
      console.warn(`Skip processReceiveMessage ${MessageTypes.broadcastVariable}: no payload`, messageData);
      return;
    }
    const variable = this.getVariablePayloadType(messageData.payload.type_url as string);
    if (variable) {
      this.receiveVariable(
        variable.fromProto(messageData.payload.value),
        Number(message.header.clientSentTimestamp),
        Number(message.header.clientReceivedTimestamp),
      );
    } else {
      console.warn(`Failed to handle received message of type ${MessageTypes.broadcastVariable}`, variable);
    }
  }

  protected getObjectPayloadType(type: string): typeof ObjectPayload | null {
    if (!type) {
      return null;
    }
    return this.objectsTypes[type].payloadType;
  }

  protected getVariablePayloadType(type: string): typeof VariablePayload | null {
    if (!this.variableTypes[type]) {
      return null;
    }
    return this.variableTypes[type].payloadType;
  }

  // TODO: optimize messages size: duplicate information
  public processSendMessagesBatch(messages: SendMessage[]) {
    messages.forEach((message) => {
      const payloadMessage = this.sendMessagesPool.getById(msgId(message));
      if (!payloadMessage) return;
      message.payload = payloadMessage?.payload;
      // if (payloadMessage.payload && payloadMessage.type === 'broadcastVariable') {
      //   const vv = VariablePayload.fromArrayBuffer(payloadMessage.payload);
      //   if (vv.name === 'animator') {
      //     console.log(AnimatorVariablePayload.fromArrayBuffer(payloadMessage.payload));
      //     console.log(message);
      //   }
      // }
      this.sendByTransport(message);
    });

    // const messagesWithPayload: Message[] = [];
    // messages.forEach((ms) => {
    //   const payloadMessage = this.sendMessagesPool.getByUid(ms.uid);
    //   this.sendMessagesPool.removeByUid(ms.uid);
    //   if (payloadMessage) messagesWithPayload.push(payloadMessage);
    // });
    // Object.values(MessageTransportType).forEach((transportType) => {
    //   const payload = messagesWithPayload.filter((pl) => pl.transportType === transportType);
    //   if (payload.length === 0) return;
    //   // split by clients
    //   // TODO: optimize & rewrite
    //   const clientMessages: Record<NetworkId, Message[]> = { '': [] };
    //   payload.forEach((ms) => {
    //     if (!ms.clients.length) {
    //       clientMessages[''].push(ms);
    //     } else {
    //       ms.clients.forEach((client) => {
    //         if (!clientMessages[client]) clientMessages[client] = [];
    //         clientMessages[client].push(ms);
    //       });
    //     }
    //   });
    //   Object.keys(clientMessages).forEach((client) => {
    //     if (!clientMessages[client] || clientMessages[client].length === 0) return;
    //     const message = MessagesPool.createMessage({
    //       type: MessageTypes.broadcastMessagesBatch,
    //       required: true, /// ?????????
    //       payload: clientMessages[client],
    //       transportType,
    //       clients: client ? [client] : [], /// ???????
    //     });
    //     this.sendByTransport(message, transportType, client ? [client] : []);
    //   });
    // });
  }

  public sendByTransport(message: SendMessage) {
    // this.debugRPS += 1;
    const actualRoomId = this.checkId(message.roomId) ? message.roomId : this.activeRoomId;
    if (typeof actualRoomId === 'undefined' || !this.checkId(actualRoomId) || !message.payload) return;
    const opts: MessageOptions = { queueId: message.queueId /* , messageId: message.id */ };
    const messageData: MessageData = {
      type: message.type,
      payload: new Uint8Array(message.payload),
    };
    const payload = MessagesPool.encodeMessage(messageData);

    if (message.transportType === ProtocolType.WS) {
      return message.clients && message.clients.length > 0
        ? this.transport.sendWSMessageTo(payload, message.clients, opts)
        : this.transport.sendWSMessageInRoom(payload, actualRoomId, opts);
    }
    if (message.transportType === ProtocolType.DC) {
      return message.clients && message.clients.length > 0
        ? this.transport.sendDCMessageTo(payload, message.clients, opts)
        : this.transport.sendDCMessageInRoom(payload, actualRoomId, opts);
    }
  }
}
