import React, { ChangeEvent, FC } from 'react';
import classnames from 'classnames';
import classNames from './Input.module.css';

export interface InputProps {
  error?: boolean;
  placeholder?: string;
  value?: string;
  width?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

const Input: FC<InputProps> = (props) => {
  const {
    onBlur = () => undefined,
    onChange = () => undefined,
    onFocus = () => undefined,
    value,
    placeholder = '',
    error = false,
    width = '100%',
  } = props;

  return (
    <input
      style={{ maxWidth: width }}
      className={classnames(classNames.input, { [classNames.input_error]: error })}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value}
      placeholder={placeholder}
    />
  );
};

export default Input;
