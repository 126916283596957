import { LightObjectsInterface, LightStateInterface} from '../types';

export const allLightObjects: LightObjectsInterface = [
];

export const lightStates: LightStateInterface = {
  night: {
    lightMaps: {
      chair: {
        path: 'lightMaps/chair_lm.jpg',
        intensity: 1,
        envIntensity: 0,
      },
      // floor: {
      //   path: 'lightMaps/floor_lm.jpg',
      //   intensity: 1,
      //   envIntensity: 0,
      // },
      monitor: {
        path: 'lightMaps/monitor_lm.jpg',
        intensity: 1,
        envIntensity: 0,
      },
    },
    lightObjects: [],
  },
};
