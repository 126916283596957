import SpawnService from '../services/Spawn.service';
import { AbstractSpaceGenerator } from '../services/SpaceZoneGenerator/AbstractSpaceGenerator';
import BaseScene from './BaseScene';
import { Application } from '../../engine/Application';
import BettySpaceGenerator from '../services/SpaceZoneGenerator/BettySpaceGenerator';

export class BettySpaceScene extends BaseScene {
  public getSpaceGeneratorService(app: Application): AbstractSpaceGenerator {
    return new BettySpaceGenerator({ app, spawnService: this.spawnService });
  }

  public getSpawnService(app: Application): SpawnService {
    return new SpawnService({ app });
  }
}
