import React, { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import { useMedia } from 'react-use';
import Form from './Form/Form';
import { nameSchemas } from '../../../../validation/schemas/NameSchemes';
import { NameValue } from '../Loader/Loader';
import classNames from './EnterScreen.module.css';
import { useControlsStore, useSpaceStore } from '../../../../hooks/stores/useSpaceStore';
import Button from '../../../../components/Button/Button';
import { mqMobileMax } from '../../../../data/variables';

export interface InputControllerProps {
  onEnter: () => void;
  onStart: () => void;
}

const EnterScreen: FC<InputControllerProps> = observer((props) => {
  const {
    onEnter = () => undefined,
    onStart = () => undefined,
  } = props;

  const isMobile = useMedia(`(max-width:${mqMobileMax})`);

  const { userName, setUserName, isValidateError } = useControlsStore();

  const { showEnterScreen, settingsCurrentSpace, showStart } = useSpaceStore();

  const { control } = useForm<NameValue>({
    resolver: yupResolver(nameSchemas),
    mode: 'onChange',
    shouldUnregister: true,
    defaultValues: { fullName: '' },
  });

  const clickEnterStartButtonHandler = useCallback(() => {
    if (showEnterScreen) onEnter();
    else onStart();
  }, [showEnterScreen]);

  const handleChangeName = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    setUserName(event.currentTarget.value);
  }, []);

  return (
    <div style={{ width: isMobile ? '100%' : 560 }}>
      {showStart && <div>
        <div className={classNames.title}>Give yourself a name</div>
        <div className={classNames.controlBox}>
          <Form
            name="fullName"
            control={control}
            onChange={handleChangeName}
            width={isMobile ? '100%' : '392px'}
            value={userName}
            placeholder="name"
          />
        </div>
      </div>}
      {showEnterScreen && <div className={classNames.formTitle}>
        <div className={classNames.formTitleText}>Welcome to OWNverse</div>
        <div className={classNames.formTitleText}>{settingsCurrentSpace?.title}</div>
      </div>}
      <div style={{ visibility: (isValidateError || userName.length < 3) ? 'hidden' : 'visible' }}>
        <Button
          title={showEnterScreen ? 'Enter' : 'Continue'}
          onClick={clickEnterStartButtonHandler}
        />
      </div>
    </div>
  );
});

export default EnterScreen;
