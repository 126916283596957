import { AbstractSpaceGenerator, PlaceMenuItem } from './AbstractSpaceGenerator';
import {
  AnimatedObject, AvatarAssetsType,
  LightObjectsInterface,
  LightStateInterface,
  MaterialType,
  SettingsType, TeleportType,
  VideoBannersType,
} from '../../assets/types';
import { settings } from '../../assets/pitchSpace/settings';
import { colliders, models } from '../../assets/pitchSpace/models';
import { lightStates, allLightObjects } from '../../assets/pitchSpace/lightStates';
import { videoBanners } from '../../assets/pitchSpace/videoBanners';
import { materials } from '../../assets/pitchSpace/materials';
import { teleports } from '../../assets/pitchSpace/teleports';
import * as avatars from '../../assets/pitchSpace/avatarsModels';
import { placeMenuItems } from '../../assets/pitchSpace/placeMenuItems';

export default class PitchSpaceGenerator extends AbstractSpaceGenerator {
  public get settings(): SettingsType {
    return settings;
  }

  public get colliderModelUrl(): string {
    return this.getSpacePath(colliders[0]);
  }

  public get spaceModelUrl(): string {
    return this.getSpacePath(models[0]);
  }

  public get avatarsAssets(): AvatarAssetsType {
    return avatars;
  }

  public get lightStates(): LightStateInterface | undefined {
    return lightStates;
  }

  public get videoBanners(): VideoBannersType | undefined {
    return videoBanners;
  }

  public get lightObjects(): LightObjectsInterface | undefined {
    return allLightObjects;
  }

  public get materials(): MaterialType[] {
    return materials;
  }

  public get teleports(): TeleportType[] {
    return teleports;
  }

  public get placesMenuObjects(): PlaceMenuItem[] {
    return placeMenuItems;
  }

  public get animatedObjects(): AnimatedObject[] {
    // TODO: move to assets
    return [{
      name: 'logo_arm',
      resetPosition: true,
      visible: false,
    }];
  }
}
