import {
  defaultAvatarAnimations,
  defaultAvatarsList, defaultAvatarsMenu,
  defaultAvatarsModels,
  defaultAvatarWalkDuration,
} from '../DefaultAvatars';

// TODO: clone default values

export const avatarsMenu = [
  { id: 'Avatar_tph', imgUrl: 'assets/ui/avatars/tph.png' },
  ...defaultAvatarsMenu,
];

export const avatars = {
  ...defaultAvatarsList,
  tph: {
    model: 'tph',
    animations: 'yuki',
  },
};

export const defaultAvatar = 'tph';

export const avatarsModels = {
  ...defaultAvatarsModels,
  tph: 'assets/avatars/VRM/toilet_paper_hands_vrm.vrm',
};

export const avatarWalkDuration = defaultAvatarWalkDuration;

export const avatarAnimations = defaultAvatarAnimations;
