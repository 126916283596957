import * as Three from 'three';
import { Reflector } from 'three/examples/jsm/objects/Reflector';
import { VRMFirstPerson } from '@pixiv/three-vrm';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { Application } from '../../../engine/Application';
import SpawnService from '../Spawn.service';
import {
  AnimatedObject,
  AudioInterface,
  AudioPlacesType,
  LightObjectsInterface,
  LightStateInterface,
  MaterialType,
  MirrorObject,
  PlaceObjectsType,
  scenePlaceNameTemplate,
  SettingsType,
  ShopifyAssetsType,
  ShopifyAssetType,
  VideoBannersType,
  VideoInterface,
  TeleportType, AvatarAssetsType,
} from '../../assets/types';
import { Entity } from '../../../engine/Entity';
import { EntityManager } from '../../../engine/EntityManager';
import { AssetSourceType, MeshRendererComponent } from '../../../engine/components/MeshRenderer.component';
import { layers } from '../../constants/sceneLayers';
import SceneLightMapsComponent, { Presets } from '../../components/SceneLightMaps.component';
import { ColliderComponent, ColliderType } from '../../../engine/components/Collider.component';
import { RigidBodyComponent } from '../../../engine/components/RigidBody.component';
import VideoObject, { VideoType } from '../../network/objects/VideoObject';
import AudioPlaceObject from '../../network/objects/AudioPlaceObject';
import { replaceObjectByEntity } from '../replaceObjectByEntity';
import RaycastComponent from '../../components/Raycast.component';
import SelectedObjectComponent from '../../components/SelectedObject.component';
import ShopifyComponent from '../../components/Shopify.component';
import { cloneGLTF } from '../../../engine/services/cloneGLTF';
import MeshLoaderComponent from '../../components/MeshLoader.component';
import VideoComponent from '../../components/VideoComponent';
import AvatarVideoComponent from '../../components/UI/AvatarVideo.component';
import { UIBuilderSystem } from '../../systems/UIBuilder.system';
import { UIDocumentComponent } from '../../../engine/components/UIDocument.component';
import { PanelId } from '../../ui/enum/PanelId';
import { ThreeMemoryCleaner } from '../../../engine/services/ThreeMemoryCleaner';
import { TeleportComponent } from '../../components/Teleport.component';
import LookAtComponent from '../../components/LookAt.component';
import PlaceMenuItemComponent from '../../components/UI/PlaceMenuItem.component';

export type SpaceGeneratorServiceOptions = {
  app: Application;
  spawnService?: SpawnService;
};

export type EntityLoadResult = { entity: Entity; content: Three.Object3D; animations?: Three.AnimationClip[] };

export type PlaceMenuItem = {
  id: string;
  scenePlaceId: string;
  text: string;
  imgUrl: string;
  sortOrder: number;
};

export abstract class AbstractSpaceGenerator {
  protected app: Application;

  protected spawnService?: SpawnService;

  public constructor(options: SpaceGeneratorServiceOptions) {
    this.app = options.app;
    this.spawnService = options.spawnService;
  }

  public abstract get settings(): SettingsType;

  public abstract get spaceModelUrl(): string;

  public abstract get colliderModelUrl(): string;

  public abstract get avatarsAssets(): AvatarAssetsType;

  public get lightStates(): LightStateInterface | undefined {
    return undefined;
  }

  public get lightObjects(): LightObjectsInterface | undefined {
    return undefined;
  }

  public get videoBanners(): VideoBannersType | undefined {
    return undefined;
  }

  public get audioPlaces(): AudioPlacesType | undefined {
    return undefined;
  }

  public get shopifyAssets(): ShopifyAssetsType | undefined {
    return undefined;
  }

  public get shadowReceiveObjects(): string[] {
    return [];
  }

  public get placeObjects(): PlaceObjectsType | undefined {
    return undefined;
  }

  public get placesMenuObjects(): PlaceMenuItem[] {
    return [];
  }

  public get scenePlaceNameTemplate(): string {
    return scenePlaceNameTemplate;
  }

  public get teleports(): TeleportType[] {
    return [];
  }

  public get mirrorsConfigs(): MirrorObject[] {
    return [];
  }

  public get wireframeObjects(): string[] {
    return [];
  }

  public get animatedObjects(): AnimatedObject[] {
    return [];
  }

  public get materials(): MaterialType[] {
    return [];
  }

  public get spaceEntityName() {
    return 'spaceEntity';
  }

  public get colliderEntityName() {
    return 'spaceCollider';
  }

  public get sceneAssetsPrefix() {
    return this.settings.baseAssetsUrl;
  }

  protected get entityManager(): EntityManager {
    return this.app.entityManager;
  }

  protected getSpacePath(modelPath: string): string {
    // FIXME: need deep clone
    return `${this.sceneAssetsPrefix}${modelPath.replace(this.sceneAssetsPrefix, '')}`;
  }

  public generateEntities(): Promise<Entity>[] {
    const promises: Promise<Entity>[] = [];
    promises.push(this.buildSpace());
    return promises;
  }

  protected cloneVideBannersData(videoBanners: VideoBannersType): VideoBannersType {
    const videos = { ...videoBanners };
    Object.keys(videos).forEach((objName) => {
      videos[objName].videos.forEach((video, index) => {
        videos[objName].videos[index] = this.cloneAndReplacePaths<VideoInterface>(
          videos[objName].videos[index], ['mp4', 'ogv', 'previewUrl'],
        );
      });
    });
    return videos;
  }

  protected cloneShopifyAssetsData(data: ShopifyAssetsType): ShopifyAssetsType {
    const clone = { ...data };
    Object.keys(clone).forEach((name) => {
      clone[name] = this.cloneAndReplacePaths<ShopifyAssetType>(clone[name], ['previewUrl']);
    });
    return clone;
  }

  protected cloneAndReplacePaths<T extends object>(data: T, pathsProperties: string[]): T {
    const clone = { ...data } as Record<string, unknown>;
    pathsProperties.forEach((name) => {
      if (clone[name]) clone[name] = this.getSpacePath(clone[name] as string);
    });
    return clone as T;
  }

  protected cloneAudioPlacesData(data: AudioPlacesType): AudioPlacesType {
    const audios = { ...data };
    Object.keys(audios).forEach((objName) => {
      audios[objName].tracks.forEach((audio, index) => {
        audios[objName].tracks[index] = this.cloneAndReplacePaths<AudioInterface>(
          audios[objName].tracks[index],
          ['mp3', 'previewUrl'],
        );
      });
    });
    return audios;
  }

  protected setUpTransparentObjects(scene: Three.Object3D) {
    scene.traverse((obj) => {
      if (obj instanceof Three.Mesh && obj.material.transparent) {
        obj.layers.set(layers.underUI);
        // obj.material.depthWrite = true;
        // obj.material.depthTest = true;
      }
    });
  }

  public loadEntity(name: string, url: string, type: AssetSourceType): Promise<EntityLoadResult> {
    const entity = this.entityManager.makeEntity();
    entity.name = name;
    return new Promise<EntityLoadResult>((resolve) => {
      entity.addComponent(MeshRendererComponent, {
        sourceData: {
          type,
          url,
        },
      }).events.on('contentAdded', ({ content, animations }) => {
        resolve({ entity, content, animations });
      });
    });
  }

  protected addSceneLightMapsComponent(entity: Entity, content: Three.Object3D) {
    if (!this.lightStates) return;
    const presets: Presets = {};
    Object.entries(this.lightStates).forEach(([name, lightState]) => {
      presets[name] = { ...lightState, ...{ objects: [...lightState.lightObjects] } };
      Object.keys(presets[name].lightMaps).forEach((lightName) => {
        presets[name].lightMaps[lightName].path = this.getSpacePath(presets[name].lightMaps[lightName].path);
      });
    });
    entity.addComponent(SceneLightMapsComponent, {
      content,
      currentPresetName: Object.keys(this.lightStates)[0],
      presets,
      objectsNames: this.lightObjects ? [...this.lightObjects] : [],
    });
  }

  protected setColliderSide(colliderData: EntityLoadResult) {
    const { content } = colliderData;
    content.visible = false;
    content.traverse((obj) => {
      // need this for raycaster
      if (obj instanceof Three.Mesh) obj.material.side = Three.DoubleSide;
    });
  }

  protected addColliderComponent(entity: Entity) {
    entity.addComponent(ColliderComponent, {
      shapeData: {
        type: ColliderType.TriangleMesh,
        meshName: 'collider',
        applyTransform: true,
      },
    });
    entity.addComponent(RigidBodyComponent);
  }

  protected setupVideoBanners(content: Three.Object3D, collider: Three.Object3D) {
    if (!this.videoBanners) return;
    const videos = this.cloneVideBannersData(this.videoBanners);
    VideoObject.setAssetsData(videos);
    Object.keys(videos).forEach((objName) => {
      const netObj = VideoObject.build(this.app, objName);
      const videoBanner = videos[objName];
      if (!netObj && this.app.networkManager) return;
      const entity = VideoObject.createEntityPrefab(
        this.app,
        content,
        objName,
        String(videoBanner.type) as VideoType,
        videoBanner.videos[0],
        videoBanner.timeline,
        videoBanner.videos,
        [collider],
      );
      if (entity) entity.getComponentOrFail(VideoComponent).autoplay = !!videoBanner.autoplay;
      if (entity && videoBanner.distance) entity.getComponentOrFail(SelectedObjectComponent).distance = videoBanner.distance;
      if (entity && videoBanner.distanceVolume) {
        entity.getComponentOrFail(VideoComponent).distanceVolume = videoBanner.distanceVolume;
      }
      if (entity && typeof videoBanner.enableSharing !== 'undefined') {
        entity.getComponentOrFail(VideoComponent).enableSharing = videoBanner.enableSharing;
      }
      if (entity && netObj) {
        netObj.attachToEntity(entity);
        netObj.getVariableByName('video')?.setNeedUpdateFromLocal();
      }
    });
  }

  protected setupAudioPlaces(content: Three.Object3D, collider: Three.Object3D) {
    if (!this.audioPlaces) return;
    const audios = this.cloneAudioPlacesData(this.audioPlaces);
    AudioPlaceObject.setAssetsData(audios);
    Object.keys(audios).forEach((objName) => {
      const netObj = AudioPlaceObject.build(this.app, objName);
      const audioPlace = audios[objName];
      if (!netObj && this.app.networkManager) return;
      const entity = AudioPlaceObject.createEntityPrefab(
        this.app,
        content,
        objName,
        audioPlace.tracks[0],
        audioPlace.tracks,
        [collider],
      );
      if (entity && netObj) {
        netObj.attachToEntity(entity);
        netObj.getVariableByName('audio')?.setNeedUpdateFromLocal();
      }
    });
  }

  protected setupShopifyAssets(content: Three.Object3D, collider: Three.Object3D) {
    if (!this.shopifyAssets) return;
    const assets = this.cloneShopifyAssetsData(this.shopifyAssets);
    Object.keys(assets).forEach((mainObjectName) => {
      const entity = this.app.entityManager.makeEntity();
      content.add(entity);
      const assetData = assets[mainObjectName];
      [mainObjectName, assetData.nameplateObject].forEach((objName) => {
        if (!objName) return;
        const obj = content.getObjectByName(objName);
        if (!obj) return;
        // hotfix one material in nameplate
        if (obj instanceof Three.Mesh) obj.material = obj.material.clone();
        const objectEntity = this.app.entityManager.makeEntity();
        replaceObjectByEntity(obj, objectEntity);
        entity.add(objectEntity);
        objectEntity.addComponent(RaycastComponent); // .colliderObjects = [collider];
        objectEntity.addComponent(SelectedObjectComponent, {
          hoverColor: new Three.Color(0.5, 0.5, 0.5),
        });
      });
      entity.addComponent(ShopifyComponent, assetData);
    });
  }

  protected enableShadows(content: Three.Object3D) {
    content.traverse((child) => {
      if (child instanceof Three.Mesh && this.shadowReceiveObjects.some((name) => child.name.startsWith(name))) {
        const shadowMesh = child.clone();
        shadowMesh.material = new Three.ShadowMaterial({ color: 0x000000, opacity: 0.2 });
        shadowMesh.receiveShadow = true;
        shadowMesh.layers.set(layers.avatarFirstPerson);
        shadowMesh.layers.enable(layers.avatarThirdPerson);
        content.add(shadowMesh);
      }
    });
  }

  protected generateSpawnSpaces(content: Three.Object3D) {
    if (!this.placeObjects) return;
    SpawnService.generatePlaces(content, this.placeObjects);
  }

  protected buildTeleports(rootEntity: Three.Object3D) {
    const prefixName = this.scenePlaceNameTemplate.replace('{}', '');
    rootEntity.traverse((obj) => {
      if (!this.spawnService) return;
      if (obj.name.startsWith(prefixName)) {
        const placeId = obj.name.replace(prefixName, '');
        const placeConfig = this.placeObjects ? this.placeObjects[placeId] : undefined;
        const placeMenuConfig = this.placesMenuObjects.length
          ? this.placesMenuObjects.find((o) => o.scenePlaceId === placeId)
          : undefined;
        const spawnEntity = this.spawnService.buildEntity(obj);
        spawnEntity.addComponent(TeleportComponent, { placeId, toggleRigidAfterTeleport: true });
        if ((placeConfig && placeConfig.isSeat) || obj.name.endsWith('_seat')) {
          this.spawnService.addSeatBehavior(spawnEntity).then(() => {
            // (this.app.sceneManager.currentScene as SpaceScene).uiBuilder.setLayers(
            //   spawnEntity.getComponentOrFail(UIDocumentComponent).root?.children[0],
            //   [layers.avatarFirstPerson, layers.avatarThirdPerson],
            // );
          });
        }
        if (placeMenuConfig) {
          spawnEntity.addComponent(PlaceMenuItemComponent, {
            text: placeMenuConfig.text,
            placeId: placeMenuConfig.id,
            imageUrl: placeMenuConfig.imgUrl,
            sortOrder: placeMenuConfig.sortOrder,
          });
        }
        rootEntity.add(spawnEntity);
      }
    });

    this.teleports.forEach((teleportData) => {
      let triggerPosition;
      if (teleportData.triggerPosition) {
        triggerPosition = teleportData.triggerPosition.clone();
      }
      let triggerMesh = null;
      if (teleportData.triggerMeshName) {
        triggerMesh = rootEntity.getObjectByName(teleportData.triggerMeshName);
        if (triggerMesh) {
          // triggerPosition.copy(triggerMesh.getWorldPosition(new Three.Vector3()));
          triggerMesh.visible = false;
        }
      }
      if (!this.spawnService) return;
      const entity = this.app.entityManager.makeEntity();// this.spawnService.buildEntity(obj);
      entity.position.set(0, 1, 0); // target place
      if (triggerMesh) {
        entity.addComponent(ColliderComponent, {
          isTrigger: true,
          applyEntityWorldMatrixOnSetup: false,
          shapeData: {
            type: ColliderType.ConvexHull,
            meshObject: triggerMesh as Three.Mesh,
            applyTransform: true,
          },
        });
      }
      entity.addComponent(TeleportComponent, {
        triggerPosition,
        spaceName: teleportData.spaceName,
      });
      rootEntity.add(entity);
      if (teleportData.textureMeshName && teleportData.texturePath) {
        const textureMesh = rootEntity.getObjectByName(teleportData.textureMeshName);
        if (textureMesh instanceof Three.Mesh) {
          new Three.TextureLoader().load(teleportData.texturePath, (texture) => {
            texture.encoding = Three.sRGBEncoding;
            texture.flipY = false;
            textureMesh.material.map = texture;
          });
        }
      }
    });
  }

  protected setupMirrors(space: Entity) {
    this.mirrorsConfigs.forEach(({ name: mirrorName, width: mirrorWidth, height: mirrorHeight }) => {
      const sceneObject = space.getObjectByName(mirrorName) as Three.Mesh;
      if (!sceneObject) return;
      const bbox = new Three.Box3().setFromObject(sceneObject);

      const width = bbox.max.x - bbox.min.x;
      const height = bbox.max.y - bbox.min.y;
      const geometry = new Three.PlaneGeometry(width, height);
      const verticalMirror = new Reflector(geometry, {
        clipBias: 0.003,
        textureWidth: mirrorWidth,
        textureHeight: mirrorHeight,
        color: 0x889999,
        encoding: Three.sRGBEncoding,
      });

      verticalMirror.position.copy(sceneObject.position);
      // TODO: get face normal and orient by normal
      // verticalMirror.rotation.x = sceneObject.rotation.y;
      verticalMirror.rotation.y = -sceneObject.rotation.z;
      // verticalMirror.rotation.z = sceneObject.rotation.x;
      // TODO: why scale??
      verticalMirror.scale.x = 1.1;

      verticalMirror.camera.layers.disable(VRMFirstPerson.DEFAULT_FIRSTPERSON_ONLY_LAYER);
      verticalMirror.camera.layers.enable(VRMFirstPerson.DEFAULT_THIRDPERSON_ONLY_LAYER);

      sceneObject.removeFromParent();
      space?.add(verticalMirror);
    });
  }

  protected setupWireframeObjects(entity: Entity) {
    this.wireframeObjects.forEach((name) => {
      const obj = entity.getObjectByName(name);
      if (!obj || !(obj instanceof Three.Mesh)) return;
      obj.material.wireframe = true;
    });
  }

  protected setupAnimatedObjects(spaceEntity: Entity, animations: Three.AnimationClip[]) {
    this.animatedObjects.forEach(({ name, resetPosition, visible }) => {
      const armature = spaceEntity.getObjectByName(name);
      if (!armature) return;
      if (resetPosition) armature.position.set(0, 0, 0);
      const clone = cloneGLTF({ scene: armature, animations } as GLTF);
      const loaderEntity = this.app.entityManager.makeEntity();
      spaceEntity.add(loaderEntity);
      loaderEntity.addComponent(MeshLoaderComponent, {
        object: clone.scene,
        clips: clone.animations,
      });
      armature.visible = visible;
    });
  }

  protected setupMaterials(scene: Three.Object3D) {
    this.materials.forEach((mat) => {
      let threeMat: Three.MeshStandardMaterial | undefined;
      scene.traverse((obj) => {
        if (!threeMat && obj instanceof Three.Mesh && obj.material && obj.material.name === mat.name) {
          threeMat = obj.material as Three.MeshStandardMaterial;
        }
      });
      if (threeMat && threeMat.emissiveMap && mat.anisotropy) {
        threeMat.emissiveMap.anisotropy = mat.anisotropy;
      }
      if (threeMat && threeMat.map && mat.anisotropy) {
        threeMat.map.anisotropy = mat.anisotropy;
      }
    });
  }

  protected setupLookAtObjects(scene: Three.Object3D) {
    const objects: Three.Object3D[] = [];
    scene.traverse((obj) => {
      if (obj.name.endsWith('_tocam')) objects.push(obj);
    });
    objects.forEach((obj) => {
      if (!this.spawnService) return;
      const entity = this.spawnService.buildEntity(obj);
      obj.position.set(0, 0, 0);
      obj.rotation.set(0, 0, 0);
      (obj.parent ?? scene).add(entity);
      entity.add(obj);
      entity.addComponent(LookAtComponent);
    });
  }

  protected afterSceneLoaded(spaceData: EntityLoadResult, colliderData: EntityLoadResult) {
    spaceData.entity.add(colliderData.entity);
    this.setColliderSide(colliderData);
    this.addColliderComponent(colliderData.entity);
    this.setupLookAtObjects(spaceData.entity);
    this.setUpTransparentObjects(spaceData.entity);
    this.addSceneLightMapsComponent(spaceData.entity, spaceData.content);
    this.setupVideoBanners(spaceData.entity, colliderData.entity);
    this.setupAudioPlaces(spaceData.entity, colliderData.entity);
    this.setupShopifyAssets(spaceData.entity, colliderData.entity);
    this.enableShadows(spaceData.entity);
    this.generateSpawnSpaces(spaceData.entity);
    this.buildTeleports(spaceData.entity);
    this.setupMirrors(spaceData.entity);
    this.setupWireframeObjects(spaceData.entity);
    this.setupAnimatedObjects(spaceData.entity, spaceData.animations || []);
    this.setupMaterials(spaceData.entity);
  }

  protected buildSpace(): Promise<Entity> {
    const spacePromise = this.loadEntity(this.spaceEntityName, this.spaceModelUrl, AssetSourceType.GLTF)
      .then((data) => {
        return data;
      });
    const colliderPromise = this.loadEntity(this.colliderEntityName, this.colliderModelUrl, AssetSourceType.GLTF);
    return Promise.all([spacePromise, colliderPromise]).then(([spaceData, colliderData]) => {
      this.afterSceneLoaded(spaceData, colliderData);
      return spaceData.entity;
    });
  }

  public createUserCamVideos(app: Application) {
    const session = app.networkManager?.sessionStore;
    if (!session) return;
    const { users } = session;
    let avatarComponents = app.componentManager.getComponentsByType(AvatarVideoComponent);
    users.forEach((user) => {
      if (!app.camera) return;
      const avatarVideoComponent = avatarComponents.find((cm) => cm.userId === user.id);
      if (!avatarVideoComponent) {
        const videoPanelEntity = app.entityManager.makeEntity();
        videoPanelEntity.name = 'videoPanelEntity';
        const component = videoPanelEntity.addComponent(AvatarVideoComponent, {
          pinnedEntity: app.camera,
          pinned: app.networkManager?.networkId === user.id,
          userId: user.id,
        });
        if (component) {
          app.getSystemOrFail(UIBuilderSystem).setupAvatarVideo(component).then(() => {
            component.entity.addComponent(VideoComponent, {
              targetMesh: component.entity.getComponentOrFail(UIDocumentComponent).getElementById(PanelId.AvatarVideo),
              flipY: true,
            });
          });
        }
      } else {
        avatarComponents = avatarComponents.filter((cm) => cm.userId !== user.id);
      }
    });
    avatarComponents.forEach((component) => {
      app.componentManager.destroyComponentsForEntity(component.entity);
      ThreeMemoryCleaner.disposeThreeGraph(component.entity);
      component.entity.removeFromParent();
    });
  }
}
