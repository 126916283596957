import { Container } from '../elements';
import { PanelId } from '../enum/PanelId';
import * as Theme from '../Theme';
import { TextBlock } from '../elements/TextBlock';
import { DialogButton } from '../elements/Buttons/DialogButton';
import { ButtonId } from '../enum/ButtonId';
import { PlayerButton } from '../elements/Buttons/PlayerButton';
import { ContainerId } from '../enum/ContainerId';
import { Component } from '../../../engine/Component';
import { TextButton } from '../elements/Buttons/TextButton';
import { TracksRow } from '../elements/Player/TracksRow';
import { Adaptive, PlayerMenuAdaptive } from '../adaptive';

export function PlayerMenuTemplate(component: Component, adaptive: Adaptive) {
  const { width, height } = adaptive;
  const {
    padding,
    borderRadius,
    tracksRowCount,
    tracksRowHeight,
    tracksPanelHeight,
    trackRowDirection,
    controlsHeight,
    controlsTitleHeight,
    controlsRowHeight,
    controlsCompact,
  } = adaptive as PlayerMenuAdaptive;
  // const percentWidth = (value: number) => (value / 100) * width;
  // const percentHeight = (value: number) => (value / 100) * height;

  return Container({
    name: 'PlayerMenu',
    width: Math.max(width, height),
    height: Math.max(width, height),
    fontName: Theme.fontMain.name,
    justifyContent: 'space-between',
    alignItems: 'start',
    contentDirection: 'column',
  }, [
    Container({
      id: PanelId.LibraryPanel,
      interactive: true,
      width,
      height: tracksPanelHeight, // width - height - padding / 2,
      backgroundOpacity: Theme.bgOpacity,
      backgroundColor: Theme.bgColor,
      borderRadius,
      padding,
      visible: component.enabled,
      justifyContent: 'space-around',
      alignItems: 'center',
      contentDirection: 'column',
    }, Array(tracksRowCount).fill(0).map((i) => TracksRow({
      width: width - 2 * padding,
      height: tracksRowHeight,
      contentDirection: trackRowDirection,
    }, [
      // Track({}),
    ]))),
    Container({
      id: PanelId.PlayerPanel,
      interactive: true,
      width,
      height: controlsHeight,
      fontName: Theme.fontMain.name,
      justifyContent: 'space-between',
      alignItems: 'start',
      contentDirection: 'column',
      backgroundOpacity: Theme.bgOpacity,
      backgroundColor: Theme.bgColor,
      borderRadius,
      padding,
      visible: component.enabled,
    }, [
      Container({
        width: width - 2 * padding,
        height: controlsTitleHeight,
        justifyContent: 'space-between',
        alignItems: 'start',
        contentDirection: 'row',
      }, [
        TextBlock({
          container: {
            id: ContainerId.ChapterName,
            width: (width - 2 * padding - controlsTitleHeight),
            height: controlsTitleHeight - padding,
            backgroundColor: '#171A30',
            backgroundOpacity: 0.5,
            borderRadius,
            alignItems: 'center',
            justifyContent: 'center',
          },
          text: {
            content: 'Text',
            fontName: Theme.fontMainBold.name,
            fontSize: 28,
            letterSpacing: -0.1,
          },
        }),
        DialogButton({
          id: ButtonId.CloseButton,
          imageUrl: 'assets/ui/icons/close.png',
          width: controlsTitleHeight - padding,
          height: controlsTitleHeight - padding,
          iconWidth: 32,
          iconHeight: 32,
        }),
      ]),
      Container({
        width: width - 2 * padding,
        height: controlsRowHeight,
        // backgroundColor: '#171A30',
        // backgroundOpacity: 0.5,
        borderRadius,
        justifyContent: 'space-between',
        alignItems: 'start',
        contentDirection: 'row',
        // padding,
      }, [
        TextButton({
          id: ButtonId.Library,
          text: 'Library',
        }),
        PlayerButton({
          id: ButtonId.PrevButton,
          imageUrl: 'assets/ui/icons/prev-track.png',
        }),
        PlayerButton({
          id: ButtonId.RewindPrev,
          imageUrl: 'assets/ui/icons/prev-15.png',
        }),
        PlayerButton({
          id: ButtonId.PlayButton,
          imageUrl: 'assets/ui/icons/play.png',
          selectedImg: 'assets/ui/icons/pause.png',
          selectedColor: '#D4FF00',
          selectedOpacity: 1,
        }),
        PlayerButton({
          id: ButtonId.RewindNext,
          imageUrl: 'assets/ui/icons/next-15.png',
        }),
        PlayerButton({
          id: ButtonId.NextButton,
          imageUrl: 'assets/ui/icons/next-track.png',
        }),
        Container({
          width: 24,
          height: 48,
        }, []),
        PlayerButton({
          id: ButtonId.Sound,
          imageUrl: 'assets/ui/icons/sound.png',
          selectedImg: 'assets/ui/icons/no-sound.png',
        }),
        PlayerButton({
          id: ButtonId.CamButton,
          imageUrl: 'assets/ui/icons/share.png',
          selectedImg: 'assets/ui/icons/unshare.png',
          selectedColor: '#5156D1',
          selected: true,
        }),
      ].slice(controlsCompact ? 1 : 0, controlsCompact ? 6 : 9)),
      Container({
        width: width - 2 * padding,
        height: controlsRowHeight,
        // backgroundColor: '#171A30',
        // backgroundOpacity: 0.5,
        borderRadius,
        justifyContent: 'space-between',
        alignItems: 'start',
        contentDirection: 'row',
        // padding,
      }, [
        TextButton({
          id: ButtonId.Library,
          text: 'Library',
          width: 115 * 2 - 48,
        }),
        PlayerButton({
          id: ButtonId.Sound,
          imageUrl: 'assets/ui/icons/sound.png',
          selectedImg: 'assets/ui/icons/no-sound.png',
        }),
        PlayerButton({
          id: ButtonId.CamButton,
          imageUrl: 'assets/ui/icons/share.png',
          selectedImg: 'assets/ui/icons/unshare.png',
          selectedColor: '#5156D1',
          selected: true,
        }),
      ]),
    ].slice(0, controlsCompact ? 3 : 2)),
  ]);
}
