import * as Three from 'three';
import { TeleportType } from '../types';

export const teleports: TeleportType[] = [
  {
    // triggerMeshName: 'portal',
    // triggerPosition: new Three.Vector3(1, 1.5, 0.149493),
    triggerMeshName: 'portal_triggerbox',
    texturePath: 'assets/ui/background/ownPitchSpaceMobile.png',
    textureMeshName: 'portal_screen',
    spaceName: 'pitchSpace',
    message: 'Do you want to teleport to PitchSpace?',
  },
];
