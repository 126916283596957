import { MaterialType } from '../types';

export const materials: MaterialType[] = [
  {
    name: 'env_day_sphere_mat',
    anisotropy: 4,
  },
  {
    name: 'env_night_sphere_mat',
    anisotropy: 4,
  },
];
