import { VideoBannersType, VideoBannerType } from '../types';

export const videoBanners: VideoBannersType = {
  tv_screen_01: {
    type: VideoBannerType.Video,
    autoplay: false,
    distance: 20,
    distanceVolume: 20,
    enableSharing: true,
    videos: [
      {
        title: 'Video 1',
        mp4: 'video/texture_video_04.mp4',
        previewUrl: 'video/preview.png',
      },
    ],
  },
  tv_screen_02: {
    type: VideoBannerType.Video,
    autoplay: false,
    distance: 20,
    distanceVolume: 20,
    enableSharing: true,
    videos: [
      {
        title: 'Video 2',
        mp4: 'video/texture_video_01.mp4',
        previewUrl: 'video/preview.png',
      },
    ],
  },
  tv_screen_03: {
    type: VideoBannerType.Video,
    autoplay: false,
    distance: 20,
    distanceVolume: 20,
    enableSharing: true,
    videos: [
      {
        title: 'Video 3',
        mp4: 'video/texture_video_01.mp4',
        previewUrl: 'video/preview.png',
      },
    ],
  },
  tv_screen_04: {
    type: VideoBannerType.Video,
    autoplay: false,
    distance: 20,
    distanceVolume: 20,
    enableSharing: true,
    videos: [
      {
        title: 'Video 4',
        mp4: 'video/texture_video_01.mp4',
        previewUrl: 'video/preview.png',
      },
    ],
  },
  tv_screen_05: {
    type: VideoBannerType.Video,
    autoplay: false,
    distance: 20,
    distanceVolume: 20,
    enableSharing: true,
    videos: [
      {
        title: 'Video 5',
        mp4: 'video/texture_video_02.mp4',
        previewUrl: 'video/preview.png',
      },
    ],
  },
  tv_screen_06: {
    type: VideoBannerType.Video,
    autoplay: false,
    distance: 20,
    distanceVolume: 20,
    enableSharing: true,
    videos: [
      {
        title: 'Video 6',
        mp4: 'video/texture_video_03.mp4',
        previewUrl: 'video/preview.png',
      },
    ],
  },
  tv_screen_07: {
    type: VideoBannerType.Video,
    autoplay: false,
    distance: 20,
    distanceVolume: 20,
    enableSharing: true,
    videos: [
      {
        title: 'Video 7',
        mp4: 'video/texture_video_02.mp4',
        previewUrl: 'video/preview.png',
      },
    ],
  },
  tv_screen_08: {
    type: VideoBannerType.Video,
    autoplay: false,
    distance: 20,
    distanceVolume: 20,
    enableSharing: true,
    videos: [
      {
        title: 'Video 8',
        mp4: 'video/texture_video_03.mp4',
        previewUrl: 'video/preview.png',
      },
    ],
  },
  tv_screen_09: {
    type: VideoBannerType.Video,
    autoplay: false,
    distance: 20,
    distanceVolume: 20,
    enableSharing: true,
    videos: [
      {
        title: 'Video 9',
        mp4: 'video/texture_video_04.mp4',
        previewUrl: 'video/preview.png',
      },
    ],
  },
};
