import { SettingsType } from '../types';

export const settings: SettingsType = {
  baseAssetsUrl: 'assets/scene/TPHSpace/',
  title: 'Toilet Paper Hands Space',
  background: {
    desktop: 'assets/ui/background/TPHSpace.png',
    mobile: 'assets/ui/background/TPHSpaceMobile.png',
  },
};
