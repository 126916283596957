import {
  defaultAvatarAnimations,
  defaultAvatarName,
  defaultAvatarsList, defaultAvatarsMenu,
  defaultAvatarsModels,
  defaultAvatarWalkDuration,
} from '../DefaultAvatars';
import { AvatarList, AvatarsMenuListType, AvatarsModels } from '../types';

// TODO: clone default values

export const avatarsMenu: AvatarsMenuListType = [
  { id: 'Avatar_male_base', imgUrl: 'assets/ui/avatars/male_base.png' },
  { id: 'Avatar_female_base', imgUrl: 'assets/ui/avatars/female_base.png' },
  ...defaultAvatarsMenu,
];
export const avatars: AvatarList = {
  male_base: {
    model: 'male_base',
    animations: 'yuki',
  },
  female_base: {
    model: 'female_base',
    animations: 'mira',
  },
  ...defaultAvatarsList,
};

export const defaultAvatar = 'male_base';

export const avatarsModels: AvatarsModels = {
  male_base: 'assets/avatars/VRM/male_base_vrm.vrm',
  female_base: 'assets/avatars/VRM/female_base_vrm.vrm',
  ...defaultAvatarsModels,
};

export const avatarWalkDuration = defaultAvatarWalkDuration;

export const avatarAnimations = defaultAvatarAnimations;
