import { TeleportType } from '../types';

export const teleports: TeleportType[] = [
  {
    triggerMeshName: 'portal_triggerbox',
    texturePath: 'assets/ui/background/ownSpaceMobile.png',
    textureMeshName: 'portal_screen',
    spaceName: 'ownSpace',
    message: 'Do you want to teleport to OWNSpace?',
  },
];
