import { AbstractSpaceGenerator, PlaceMenuItem } from './AbstractSpaceGenerator';
import {
  AnimatedObject,
  AudioPlacesType, AvatarAssetsType,
  LightObjectsInterface,
  LightStateInterface, MirrorObject, PlaceObjectsType,
  SettingsType,
  ShopifyAssetsType, TeleportType,
  VideoBannersType,
} from '../../assets/types';
import { settings } from '../../assets/ownSpace/settings';
import { colliders, models, shadowReceiveObjects, wireframeObjects } from '../../assets/ownSpace/models';
import { allLightObjects, lightStates } from '../../assets/ownSpace/lightStates';
import { videoBanners } from '../../assets/ownSpace/videoBanners';
import { AudioPlaces } from '../../assets/ownSpace/audioTracks';
import { ShopifyAssets } from '../../assets/ownSpace/shopifyModels';
import { mirrorName, textureHeight, textureWidth } from '../../assets/ownSpace/mirrors';
import { teleports } from '../../assets/ownSpace/teleports';
import * as avatars from '../../assets/ownSpace/avatarsModels';
import { placeMenuItems } from '../../assets/ownSpace/placeMenuItems';

export default class OwnSpaceGenerator extends AbstractSpaceGenerator {
  public get settings(): SettingsType {
    return settings;
  }

  get colliderModelUrl(): string {
    return this.getSpacePath(colliders[0]);
  }

  get spaceModelUrl(): string {
    return this.getSpacePath(models[0]);
  }

  public get avatarsAssets(): AvatarAssetsType {
    return avatars;
  }

  public get lightStates(): LightStateInterface | undefined {
    return lightStates;
  }

  public get lightObjects(): LightObjectsInterface | undefined {
    return allLightObjects;
  }

  public get videoBanners(): VideoBannersType | undefined {
    return videoBanners;
  }

  public get audioPlaces(): AudioPlacesType | undefined {
    return AudioPlaces;
  }

  public get shopifyAssets(): ShopifyAssetsType | undefined {
    return ShopifyAssets;
  }

  public get shadowReceiveObjects(): string[] {
    return shadowReceiveObjects;
  }

  public get placeObjects(): PlaceObjectsType | undefined {
    return undefined;
  }

  public get placesMenuObjects(): PlaceMenuItem[] {
    return placeMenuItems;
  }

  public get teleports(): TeleportType[] {
    return teleports;
  }

  public get mirrorsConfigs(): MirrorObject[] {
    return [{
      name: mirrorName,
      width: textureWidth,
      height: textureHeight,
    }];
  }

  public get wireframeObjects(): string[] {
    return wireframeObjects;
  }

  public get animatedObjects(): AnimatedObject[] {
    return [{
      name: 'logo_arm',
      resetPosition: true,
      visible: false,
    }];
  }
}
