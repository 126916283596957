import { FC } from 'react';
import { IconProps } from './iconTypes';
import Camera from './Camera';
import SlashCamera from './SlashCamera';
import Microphone from './Microphone';
import SlashMicrophone from './SlashMicrophone';
import Sound from './Sound';
import SlashSound from './SlashSound';
import Bullit from './Bullit';

const iconsDict: Record<string, FC<IconProps>> = {
  CameraIcon: Camera,
  SlashCameraIcon: SlashCamera,
  MicrophoneIcon: Microphone,
  SlashMicrophoneIcon: SlashMicrophone,
  SoundIcon: Sound,
  SlashSoundIcon: SlashSound,
  BullitIcon: Bullit,
};

export default iconsDict;
