import { VideoBannersType, VideoBannerType } from '../types';

export const videoBanners: VideoBannersType = {
  artstation_plane: {
    type: VideoBannerType.Presentation,
    distance: 20,
    videos: [{
      mp4: 'video/artstation.mp4',
      ogv: 'video/artstation.ogv',
    }],
    timeline: [
      {
        name: 'Intro',
        timeStamp: 0,
      },
      {
        name: 'Mira slide 1',
        timeStamp: 2.35,
      },
      {
        name: 'Yuki slide 1',
        timeStamp: 4.35,
      },
      {
        name: 'Mira slide 2',
        timeStamp: 6.35,
      },
      {
        name: 'Yuki slide 2',
        timeStamp: 8.35,
      },
      {
        name: 'Outro',
        timeStamp: 10.35,
      },
    ],
  },
  behance_plane: {
    type: VideoBannerType.Presentation,
    distance: 20,
    videos: [{
      mp4: 'video/behance.mp4',
      ogv: 'video/behance.ogv',
    }],
    timeline: [
      {
        name: 'Intro',
        timeStamp: 0,
      },
      {
        name: 'Mira slide 1',
        timeStamp: 2.35,
      },
      {
        name: 'Yuki slide 1',
        timeStamp: 4.35,
      },
      {
        name: 'Mira slide 2',
        timeStamp: 6.35,
      },
      {
        name: 'Yuki slide 2',
        timeStamp: 8.35,
      },
      {
        name: 'Outro',
        timeStamp: 10.35,
      },
    ],
  },
  // youtube1_plane: {
  canva_plane: {
    type: VideoBannerType.Presentation,
    distance: 20,
    videos: [{
      mp4: 'video/canva.mp4',
      ogv: 'video/canva.ogv',
    }],
    timeline: [
      {
        name: 'OWNverse',
        timeStamp: 0,
      },
      {
        name: 'Solution',
        timeStamp: 3.02,
      },
      {
        name: 'Features',
        timeStamp: 6.03,
      },
      {
        name: 'Mission',
        timeStamp: 9.45,
      },
    ],
  },
  youtube1_plane: {
  // canva_plane: {
    type: VideoBannerType.Video,
    distance: 25,
    videos: [
      {
        title: 'OWNverse Demo',
        mp4: 'video/youtube/ownverse_demovideo.mp4',
        ogv: 'video/youtube/ownverse_demovideo.ogv',
        previewUrl: 'video/youtube/test-preview.png',
      },
      {
        title: 'Beach',
        mp4: 'video/youtube/beach.mp4',
        ogv: 'video/youtube/beach.ogv',
        previewUrl: 'video/youtube/test-preview-1.png',
      },
      {
        title: 'Fox',
        mp4: 'video/youtube/fox.mp4',
        ogv: 'video/youtube/fox.ogv',
        previewUrl: 'video/youtube/test-preview-2.png',
      },
      {
        title: 'Drone',
        mp4: 'video/youtube/drone.mp4',
        ogv: 'video/youtube/drone.ogv',
        previewUrl: 'video/youtube/test-preview-3.png',
      },
    ],
  },
  youtube2_plane: {
    type: VideoBannerType.Video,
    distance: 25,
    videos: [{
      title: 'OWNverse Demo',
      mp4: 'video/youtube/ownverse_demovideo.mp4',
      ogv: 'video/youtube/ownverse_demovideo.ogv',
      previewUrl: 'video/youtube/test-preview-4.png',
    }],
  },
};
