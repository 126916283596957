import { ShopifyAssetsType } from '../types';

export enum ShopifyObjectNames {
  Desk = 'shopify_obj_1',
  Bag = 'shopify_obj_2',
  TShirt = 'shopify_obj_3',
  Bottle = 'shopify_obj_4',
  Watch = 'shopify_obj_5',
}

export const ShopifyAssets: ShopifyAssetsType = {
  [ShopifyObjectNames.Desk]: {
    title: 'Desk',
    nameplateObject: 'shopify_label_1',
    description: `Get your work done in style with our sleek and modern desk. 
    Its compact design makes it perfect for small spaces, and its durable construction ensures it will last for years to come. 
    Assembly is a breeze, so you can start using it right away.`,
    previewUrl: 'assets/scene/shopify/shopify_img_1.jpg',
  },
  [ShopifyObjectNames.Bag]: {
    title: 'Bag',
    nameplateObject: 'shopify_label_2',
    description: `Our bag is the perfect companion for all of your adventures. 
    Its durable and water-resistant material will keep your belongings safe and dry, while its stylish design adds a touch of flair to any outfit.`,
    previewUrl: 'shopify/shopify_img_2.jpg',
  },
  [ShopifyObjectNames.TShirt]: {
    title: 'TShirt',
    nameplateObject: 'shopify_label_3',
    description: `Stay comfortable and stylish in our high-quality t-shirt. 
    Made from soft, breathable fabric, this shirt is perfect for any occasion. 
    It features a classic fit, short sleeves, and a crew neck, making it a versatile addition to your wardrobe.`,
    previewUrl: 'shopify/shopify_img_3.jpg',
  },
  [ShopifyObjectNames.Bottle]: {
    title: 'Bottle',
    nameplateObject: 'shopify_label_4',
    description: `Stay hydrated on the go with our durable and stylish bottle. 
    Made from high-quality stainless steel, this bottle is built to last. 
    It features a leak-proof design and a convenient carry handle, making it perfect for the office, gym, or outdoor adventures.`,
    previewUrl: 'shopify/shopify_img_4.jpg',
  },
  [ShopifyObjectNames.Watch]: {
    title: 'Watch',
    nameplateObject: 'shopify_label_5',
    description: `Keep track of time in style with our elegant and sophisticated watch. 
    This timepiece features a classic design with a durable stainless steel case, genuine leather strap, and scratch-resistant crystal face. 
    The precise quartz movement and easy-to-read display make it a reliable choice for everyday wear.`,
    previewUrl: 'shopify/shopify_img_5.jpg',
  },
};
