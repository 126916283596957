import {
  defaultAvatarAnimations,
  defaultAvatarName,
  defaultAvatarsList, defaultAvatarsMenu,
  defaultAvatarsModels,
  defaultAvatarWalkDuration,
} from '../DefaultAvatars';
import { AvatarList, AvatarsMenuListType, AvatarsModels } from '../types';

// TODO: clone default values

export const avatarsMenu: AvatarsMenuListType = [
  { id: 'Avatar_male_base', imgUrl: 'assets/ui/avatars/male_base.png' },
  { id: 'Avatar_female_base', imgUrl: 'assets/ui/avatars/female_base.png' },
  { id: 'Avatar_alex', imgUrl: 'assets/ui/avatars/alex.png' },
  { id: 'Avatar_sergey', imgUrl: 'assets/ui/avatars/sergey.png' },
  { id: 'Avatar_stefan', imgUrl: 'assets/ui/avatars/stefan.png' },
  // ...defaultAvatarsMenu,
];
export const avatars: AvatarList = {
  male_base: {
    model: 'male_base',
    animations: 'yuki',
  },
  female_base: {
    model: 'female_base',
    animations: 'mira',
  },
  alex: {
    model: 'alex',
    animations: 'yuki',
  },
  sergey: {
    model: 'sergey',
    animations: 'yuki',
  },
  stefan: {
    model: 'stefan',
    animations: 'yuki',
  },
  // ...defaultAvatarsList,
};

export const defaultAvatar = 'male_base';

export const avatarsModels: AvatarsModels = {
  male_base: 'assets/avatars/VRM/male_base_vrm.vrm',
  female_base: 'assets/avatars/VRM/female_base_vrm.vrm',
  alex: 'assets/avatars/VRM/alex_vrm.vrm',
  sergey: 'assets/avatars/VRM/sergey_vrm.vrm',
  stefan: 'assets/avatars/VRM/stefan_vrm.vrm',
  // ...defaultAvatarsModels,
};

export const avatarWalkDuration = defaultAvatarWalkDuration;

export const avatarAnimations = defaultAvatarAnimations;
