import { VideoBannersType, VideoBannerType } from '../types';

export const videoBanners: VideoBannersType = {
  screen_1: {
    type: VideoBannerType.Video,
    autoplay: true,
    videos: [
      {
        title: 'OWNverse Intro',
        mp4: 'video/intro.mp4',
        ogv: 'video/intro.ogv',
        previewUrl: 'video/preview.png',
      },
      {
        title: 'OWNverse Pitch Deck',
        mp4: 'video/secondVideo/video.mp4',
        ogv: 'video/secondVideo/video.ogv',
        previewUrl: 'video/secondVideo/preview.png',
      },
    ],
  },
};
