import { VideoBannersType, VideoBannerType } from '../types';

export const videoBanners: VideoBannersType = {
  tiktok_screen: {
    type: VideoBannerType.Video,
    autoplay: true,
    distance: 20,
    distanceVolume: 12,
    enableSharing: false,
    videos: [
      {
        title: 'Vecna',
        mp4: 'video/TikTok_screen/TikTok_1_Vecna.mp4',
        previewUrl: 'video/TikTok_screen/TikTok_1_Vecna.png',
      },
      {
        title: 'Hulk',
        mp4: 'video/TikTok_screen/TikTok_2_Hulk.mp4',
        previewUrl: 'video/TikTok_screen/TikTok_2_Hulk.png',
      },
      {
        title: 'Wednesday Addams',
        mp4: 'video/TikTok_screen/TikTok_3_Wednesday_Addams.mp4',
        previewUrl: 'video/TikTok_screen/TikTok_3_Wednesday_Addams.png',
      },
    ],
  },
  artstation_screen: {
    type: VideoBannerType.Video,
    autoplay: true,
    distance: 20,
    distanceVolume: 12,
    enableSharing: false,
    videos: [
      {
        title: 'ArtStation',
        mp4: 'video/ArtStation_screen/ArtStation.mp4',
        previewUrl: 'video/ArtStation_screen/ArtStation.png',
      },
    ],
  },
  instagram_screen: {
    type: VideoBannerType.Video,
    autoplay: true,
    distance: 20,
    distanceVolume: 12,
    enableSharing: false,
    videos: [
      {
        title: 'Instagram',
        mp4: 'video/Instagram_screen/Instagram.mp4',
        previewUrl: 'video/Instagram_screen/Instagram.png',
      },
    ],
  },
  twitch_screen: {
    type: VideoBannerType.Video,
    autoplay: true,
    distance: 20,
    distanceVolume: 12,
    enableSharing: true,
    videos: [
      {
        title: 'Twitch',
        mp4: 'video/Twitch_screen/Twitch.mp4',
        previewUrl: 'video/Twitch_screen/Twitch.png',
      },
    ],
  },
  youtube_screen: {
    type: VideoBannerType.Video,
    autoplay: true,
    distance: 20,
    distanceVolume: 12,
    enableSharing: false,
    videos: [
      {
        title: 'Working with movies',
        mp4: 'video/YouTube_screen/YouTube_1.mp4',
        previewUrl: 'video/YouTube_screen/YouTube_1.png',
      },
      {
        title: 'Creature Design',
        mp4: 'video/YouTube_screen/YouTube_2.mp4',
        previewUrl: 'video/YouTube_screen/YouTube_2.png',
      },
    ],
  },
};
