import { SettingsType } from '../types';

export const settings: SettingsType = {
  baseAssetsUrl: 'assets/scene/bettySpace/',
  title: 'Bettys Bar',
  background: {
    desktop: 'assets/ui/background/BettySpace.png',
    mobile: 'assets/ui/background/BettyMobileSpace.png',
  },
};
