import * as Three from 'three';
import { Adaptive } from './Adaptive';
import { OwnSpaceScene } from '../../scenes/OwnSpaceScene';
import { AvatarsMenuListType } from '../../assets/types';
import BaseScene from '../../scenes/BaseScene';
import PlaceMenuItemComponent from "../../components/UI/PlaceMenuItem.component";

export class MainMenuAdaptive extends Adaptive {
  get isMobile() {
    return this.elementSize.width < 700 || this.elementSize.width < this.elementSize.height;
  }

  get width() {
    return this.isMobile ? 328 : 626;
  }

  get buttonSize() {
    return this.isMobile ? 36 : 48;
  }

  get height() {
    return (this.isMobile ? 490 : 440 + (this.isVrSupported || this.isVRSession ? 76 : 0))
      + this.placeElementHeight + this.padding / 2;
  }

  get scale() {
    const base = 0.00055;
    const kf = Adaptive.isTouchDevice() && (this.elementSize.width > this.elementSize.height) ? 1.8 : 1;
    const kfVR = this.isVRSession ? 1.7 : 1;
    return kfVR * kf * (this.isMobile ? this.getPixelRatioScale(base) : base);
  }

  get showPlacesTabs() {
    return !!this.app?.componentManager.getComponentsByType(PlaceMenuItemComponent).length;
    // return this.app?.sceneManager.currentScene instanceof OwnSpaceScene;
  }

  get avatarsMenuItems(): AvatarsMenuListType | [] {
    if (!this.app) return [];
    return (this.app.sceneManager.currentScene as BaseScene).getSpaceGeneratorService(this.app).avatarsAssets.avatarsMenu;
  }

  get padding() {
    return this.isMobile ? 16 : 32;
  }

  get space() {
    return this.isMobile ? 2 : 30;
  }

  get captionFontSize() {
    return this.isMobile ? 28 : 48;
  }

  get tabsContainerHeight() {
    return this.isMobile ? 64 : 76;
  }

  get tabsFontSize() {
    return this.isMobile ? 16 : 28;
  }

  get avatarItemWidth() {
    return this.isMobile ? 130 : 125;
  }

  get avatarItemHeight() {
    return this.isMobile ? 136 : 200;
  }

  get avatarsInRow() {
    return this.isMobile ? 2 : 4;
  }

  get contentHeight() {
    return (this.isMobile ? 300 : 224) + this.placeElementHeight + this.padding / 2;
  }

  get placeElementWidth() {
    return this.isMobile ? 132 : 260;
  }

  get placeElementHeight() {
    return this.isMobile ? 42 : 48;
  }

  get placeMenuMinRows(): number {
    return this.isMobile ? 7 : 5;
  }

  get position() {
    return new Three.Vector3(
      this.isMobile ? 0 : 0.1,
      this.isVRSession ? this.headPositionY : 0.01,
      -this.z,
    );
  }
}
