export const fontMain = {
  name: 'SpaceGrotesk',
  texture: 'assets/fonts/SpaceGrotesk/SpaceGrotesk-Regular.png',
  json: 'assets/fonts/SpaceGrotesk/SpaceGrotesk-Regular-msdf.json',
};

export const fontMainBold = {
  name: 'SpaceGroteskBold',
  texture: 'assets/fonts/SpaceGrotesk/SpaceGrotesk-Bold.png',
  json: 'assets/fonts/SpaceGrotesk/SpaceGrotesk-Bold-msdf.json',
};

export const bgColor = '#171A30';
export const textColor = '#FFFFFF';
export const bgOpacity = 0.9;

export const bgBorderRadius: [number, number, number, number] = [24, 24, 24, 24];

export const seatIcons = {
  default: 'assets/ui/icons/icon_seat.png',
  hover: 'assets/ui/icons/icon_seat-hover.png',
  active: 'assets/ui/icons/icon_seat-active.png',
};
