import { Container } from '../elements';
import { Component } from '../../../engine/Component';
import { PanelId } from '../enum/PanelId';
import { GreenButton } from '../elements/Buttons/GreenButton';
import { ButtonId } from '../enum/ButtonId';
import { TextBlock } from '../elements/TextBlock';
import * as Theme from '../Theme';
import { DialogButton } from '../elements/Buttons/DialogButton';
import { ContainerId } from '../enum/ContainerId';
import { Adaptive, ShopifyMenuAdaptive } from '../adaptive';

export function ShopifyMenuTemplate(component: Component, adaptive: Adaptive) {
  const { width, height } = adaptive;
  const {
    borderRadius,
    padding,
    contentDirection,
    imagePanelHeight,
    imagePanelWidth,
    imageHeight,
    buttonWithImage,
    textPanelWidth,
    textPanelHeight,
    titleHeight,
    titleSpace,
    descriptionHeight,
  } = adaptive as ShopifyMenuAdaptive;
  return Container({
    id: PanelId.ShopifyPanel,
    interactive: true,
    width,
    height,
    contentDirection,
    justifyContent: 'space-between',
    alignItems: 'start',
  }, [
    Container({
      width: imagePanelWidth,
      height: imagePanelHeight,
      borderRadius,
      backgroundColor: '#171A30',
      backgroundOpacity: 0.9,
      padding,
      contentDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'start',
    }, [
      Container({
        id: PanelId.ShopifyImage,
        width: imagePanelWidth - padding * 2,
        height: imageHeight,
        backgroundImage: 'assets/scene/ownSpace/shopify/preview.jpg',
        backgroundOpacity: 1,
        borderRadius,
        backgroundSize: 'contain',
      }, []),
      GreenButton({
        id: ButtonId.ShopifyButton,
        title: 'Open in Shopify',
      }),
    ].slice(0, buttonWithImage ? 2 : 1)),
    Container({
      width: textPanelWidth,
      height: textPanelHeight,
      borderRadius,
      backgroundColor: '#171A30',
      backgroundOpacity: 0.9,
      padding,
      contentDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'start',
    }, [
      Container({
        width: textPanelWidth - padding * 2,
        height: titleHeight,
        contentDirection: 'row',
        justifyContent: 'start',
        alignItems: 'start',
      }, [
        TextBlock({
          text: {
            fontName: Theme.fontMainBold.name,
            content: 'Hp racchetta padlle legend in carboniohp',
            letterSpacing: -0.1,
            fontSize: 26,
          },
          container: {
            id: ContainerId.ShopifyTitle,
            width: (textPanelWidth - 16 * 2) * 0.85,
            height: titleHeight,
            textAlign: 'left',
          },
        }),
        DialogButton({
          id: ButtonId.CloseButton,
          imageUrl: 'assets/ui/icons/close.png',
          iconWidth: 32,
          iconHeight: 32,
        }),
      ]),
      Container({
        width: textPanelWidth - padding * 2,
        height: titleSpace,
      }, []),
      TextBlock({
        text: {
          fontName: Theme.fontMain.name,
          content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus tellus id congue semper cras at tempus. Enim etiam accumsan volutpat eget pretium ut velit, augue nibh. Nunc, sed magna et eu sed elit malesuada hendrerit. Aenean scelerisque in massa blandit lectus et urna. ',
          letterSpacing: -0.1,
          fontSize: 18,
        },
        container: {
          id: ContainerId.ShopifyDescription,
          width: textPanelWidth - padding * 2,
          height: descriptionHeight,
          textAlign: 'left',
        },
      }),
      GreenButton({
        id: ButtonId.ShopifyButton,
        title: 'Open in Shopify',
      }),
    ].slice(0, buttonWithImage ? 3 : 4)),
  ]);
}
