import { FC, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useMedia } from 'react-use';
import classnames from 'classnames';
import classNames from './Loader.module.css';
import { useSpaceStore } from '../../../../hooks/stores/useSpaceStore';
import LoaderAnimation from './components/LoaderAnimation';
import { mqMobileMax } from '../../../../data/variables';

export interface NameValue {
  fullName: string;
}

const Loader: FC = observer(() => {
  const {
    isLoading,
    settingsCurrentSpace,
  } = useSpaceStore();

  const isMobile = useMedia(`(max-width:${mqMobileMax})`);
  const background = isMobile ? settingsCurrentSpace?.background.mobile : settingsCurrentSpace?.background.desktop;

  const currentBackground = useMemo(() => {
    if (background) return `url(${background})`;
  }, [background]);

  return (
    <div
      style={{
        background: currentBackground,
      }}
      className={classnames(
        classNames.root,
        { [classNames.rootShow]: isLoading },
        { [classNames.rootHide]: !isLoading },
      )}
    >
      <div
        className={classnames(
          classNames.background,
          { [classNames.noneBackground]: background },
        )}
      />
      <LoaderAnimation />
    </div>
  );
});

export default Loader;
