import { VideoBannersType, VideoBannerType } from '../types';

export const videoBanners: VideoBannersType = {
  tv_main_screen: {
    type: VideoBannerType.Presentation,
    autoplay: false,
    distance: 20,
    distanceVolume: 30,
    enableSharing: true,
    videos: [
      {
        title: 'Pitchdeck',
        mp4: 'video/OnlyVC.mp4',
        previewUrl: 'video/Only_Black.png',
      },
    ],
    timeline: [
      {
        name: 'OnlyVC Black',
        timeStamp: 0,
      },
      {
        name: 'OnlyVC White',
        timeStamp: 5.5,
      },
    ],
  },
  day_tv_screen_logo: {
    type: VideoBannerType.Video,
    autoplay: true,
    distance: 0,
    distanceVolume: 30,
    enableSharing: false,
    videos: [
      {
        title: 'Day',
        mp4: 'video/video_partners_day.mp4',
        previewUrl: 'video/Only_Black.png',
      },
    ],
  },
  night_tv_screen_logo: {
    type: VideoBannerType.Video,
    autoplay: true,
    distance: 0,
    distanceVolume: 30,
    enableSharing: false,
    videos: [
      {
        title: 'Night',
        mp4: 'video/video_partners_night.mp4',
        previewUrl: 'video/Only_Black.png',
      },
    ],
  },
};
