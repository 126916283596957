import { Participant } from '@voxeet/voxeet-web-sdk/types/models/Participant';
import { VideoServiceInterface, VideoStatus } from '../types';
import ConferenceService from './Conference.service';

export type StreamType = 'main' | 'share';

export type ParticipantsStatuses = { [key: string]: { spiking: boolean } };

export default class VideoService implements VideoServiceInterface {
  public conference: ConferenceService;

  public changeVideoInProgress = false;

  public localVideoEnabled = false;

  public localShareEnabled = false;

  public loopHandler: ReturnType<typeof setInterval> | null = null;

  public participantsStatuses: ParticipantsStatuses = {};

  public streams: Record<string, Record<StreamType, MediaStream | undefined>> = {};

  public c = 0;

  public constructor(conference: ConferenceService) {
    this.conference = conference;
  }

  get externService() {
    return this.conference.externService;
  }

  public get isVideoEnabled() {
    return this.localVideoEnabled;
  }

  public get isScreenShareEnabled() {
    return this.localShareEnabled;
  }

  public isShareEnabled() {
    return this.localShareEnabled;
  }

  public getUserVideoStatus(externalId: string): VideoStatus {
    const user = this.conference.findUserById(externalId);
    if (!user || !this.streams[externalId]) return VideoStatus.Disabled;
    if (this.streams[externalId].main && this.streams[externalId].share) return VideoStatus.ActiveMainAndShare;
    if (this.streams[externalId].main) return VideoStatus.ActiveMain;
    if (this.streams[externalId].share) return VideoStatus.ActiveShare;
    if (user.videoTransmitting) return VideoStatus.Muted;
    return VideoStatus.Disabled;
  }

  public getUserStream(externalId: string): MediaStream | undefined {
    return this.streams[externalId] ? this.streams[externalId].main : undefined;
  }

  public getUserShareStream(externalId: string): MediaStream | undefined {
    return this.streams[externalId] ? this.streams[externalId].share : undefined;
  }

  public getUserSpikingStatus(externalId: string): boolean {
    return this.participantsStatuses[externalId]?.spiking;
  }

  protected addVideoNode(participant: Participant, stream: MediaStream) {
    const videoNode = document.createElement('video');

    videoNode.setAttribute('id', `video-${participant.id}`);
    videoNode.setAttribute('height', '240');
    videoNode.setAttribute('width', '320');
    videoNode.setAttribute('playsinline', 'playsinline');
    videoNode.muted = true;
    videoNode.style.position = 'absolute';
    videoNode.style.top = `${this.c * 245}px`;
    videoNode.setAttribute('autoplay', 'autoplay');

    document.body.appendChild(videoNode);
    videoNode.srcObject = stream;
    this.c += 1;
  }

  public addVideoStream(participant: Participant, stream: MediaStream, type: StreamType) {
    const userId = participant.info.externalId;
    if (!userId) return;
    if (!this.streams[userId]) this.streams[userId] = { main: undefined, share: undefined };
    this.streams[userId][type] = stream;
  }

  public removeVideoStream(participant: Participant, type: StreamType) {
    // console.log('removeVideoStream', participant.info.externalId, type);
    if (!participant.info.externalId || !this.streams[participant.info.externalId]) return;
    delete this.streams[participant.info.externalId][type];
  }

  public afterJoin() {
    const onStream = (participant: Participant, stream: any) => {
      // console.log('stream', stream);
      const type: StreamType = stream.type === 'ScreenShare' ? 'share' : 'main';
      if (stream.getVideoTracks().length || stream.type === 'ScreenShare') {
        // this.addVideoNode(participant, stream);
        this.addVideoStream(participant, stream, type);
      }
      if (stream.getVideoTracks().length === 0) {
        this.removeVideoStream(participant, type);
      }
    };

    this.externService.conference.on('streamAdded', onStream);
    this.externService.conference.on('streamUpdated', onStream);
    this.externService.conference.on('streamRemoved', (participant, stream) => {
      const type: StreamType = stream.type === 'ScreenShare' ? 'share' : 'main';
      this.removeVideoStream(participant, type);
    });

    this.runLoop();
    this.externService.conference.on('left', () => {
      if (this.loopHandler) clearInterval(this.loopHandler);
    });

    return Promise.resolve();
  }

  protected runLoop(interval = 500) {
    this.loopHandler = setInterval(() => {
      this.loopProcess();
    }, interval);
  }

  protected loopProcess() {
    this.participantsStatuses = {};
    this.externService.conference.participants.forEach((user) => {
      const id = user.info.externalId;
      if (!id) return;
      this.participantsStatuses[id] = { spiking: false };
      this.externService.conference.isSpeaking(user, (value: boolean) => {
        this.participantsStatuses[id].spiking = value;
      });
    });
  }

  public turnUserCameraById(externalId: string, value: boolean) {
    const user = this.conference.findUserById(externalId);
    if (!user) return Promise.resolve();
    if (!value) return this.externService.video.remote.stop(user);
    return this.externService.video.remote.start(user);
  }

  public enableVideo() {
    this.changeVideoInProgress = true;
    // TODO: move to options
    return this.externService.video.local.start({
      width: {
        min: 320,
        max: 640,
      },
      height: {
        min: 240,
        max: 480,
      },
    })
      .then(() => {
        this.localVideoEnabled = true;
        this.changeVideoInProgress = false;
      })
      .catch((err) => {
        console.log(err);
        //
      });
  }

  public disableVideo() {
    this.changeVideoInProgress = true;
    return this.externService.video.local.stop()
      .then(() => {
        this.localVideoEnabled = false;
        this.changeVideoInProgress = false;
      })
      .catch((err) => {
        console.log(err);
        //
      });
  }

  public enableScreenShare() {
    this.changeVideoInProgress = true;
    return this.externService.conference.startScreenShare()
      .then(() => {
        this.localShareEnabled = true;
        this.changeVideoInProgress = false;
      })
      .catch((err: unknown) => {
        console.log(err);
        //
      });
  }

  public disableScreenShare() {
    this.changeVideoInProgress = true;
    return this.externService.conference.stopScreenShare()
      .then(() => {
        this.localShareEnabled = false;
        this.changeVideoInProgress = false;
      })
      .catch((err) => {
        console.log(err);
        //
      });
  }

  public toggleVideo() {
    if (this.isVideoEnabled) return this.disableVideo();
    return this.enableVideo();
  }

  public toggleScreenShare() {
    const id = this.externService.session.participant.info.externalId;
    if (this.localShareEnabled || (id && this.streams[id] && this.streams[id].share)) return this.disableScreenShare();
    return this.enableScreenShare();
  }
}
