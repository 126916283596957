import * as Three from 'three';
import WelcomeScreenComponent from '../../components/UI/WelcomeScreen.component';
import { Button, Container } from '../elements';
import { PanelId } from '../enum/PanelId';
import * as Theme from '../Theme';
import { TextBlock } from '../elements/TextBlock';
import { DialogButton } from '../elements/Buttons/DialogButton';
import { ButtonId } from '../enum/ButtonId';
import { SmallCard } from '../elements/WelcomePanel/SmallCard';
import { GridRows } from '../elements/GridRows';
import { Adaptive } from '../adaptive';
import { WelcomeScreenAdaptive } from '../adaptive/WelcomeScreen.adaptive';
import { Component } from '../../../engine/Component';
import { RadioSwitch } from '../elements/RadioSwitch';
import { TabsId } from '../enum/TabsId';

export function WelcomeScreenTemplate(component: Component, adaptive: Adaptive) {
  const { width, height } = adaptive;
  const {
    padding,
    countByRows,
    contentDirection,
    rowsContent,
    rowHeight,
    space,
    bottomHeight,
    rowFontSize,
    bottomRowHeight,
    margin,
    isVRSession,
    isMobile,
    isVrSupported,
  } = adaptive as WelcomeScreenAdaptive;

  const percentWidth = (value: number) => (value / 100) * width;
  const percentHeight = (value: number) => (value / 100) * height;

  const content = !isVRSession ? [...GridRows({
    countByRows,
    width: width - padding * 2,
    height: rowHeight,
    contentDirection: 'row',
    justifyContent: 'space-around',
  }, rowsContent.map((row) => {
    return SmallCard(row);
  })),
  Container({
    width: width - padding * 2,
    height: 1,
    backgroundColor: '#FFFFFF',
    backgroundOpacity: 1,
    margin: space,
  }, []),
  Container({
    width: width - padding * 2,
    height: bottomHeight,
    contentDirection,
    justifyContent: 'space-around',
  }, [
    SmallCard({
      width: Math.min(365, width - 2 * padding),
      iconWidth: 48,
      iconHeight: 48,
      imageUrl: 'assets/ui/welcome/seat.png',
      text: 'Click on the button near with benches to seat and click on the Esc to stand up',
      fontSize: rowFontSize,
      height: bottomRowHeight,
    }),
    SmallCard({
      width: Math.min(365, width - 2 * padding),
      margin,
      iconWidth: 200,
      iconHeight: 48,
      imageUrl: 'assets/ui/welcome/play-actions.png',
      text: 'Click interaction buttons and customise options',
      fontSize: rowFontSize,
      height: bottomRowHeight,
    }),
  ])]
    : [Container({
      width: width - 2 * padding,
      height: height - 2 * padding - 130 - 56,
      backgroundImage: 'assets/ui/welcome/vr.png',
      backgroundOpacity: 1,
      backgroundSize: 'contain',
    }, [])];

  return Container({
    id: PanelId.WelcomePanel,
    interactive: true,
    width,
    height,
    fontName: Theme.fontMain.name,
    justifyContent: 'start',
    alignItems: 'start',
    contentDirection: 'column',
    backgroundOpacity: Theme.bgOpacity,
    backgroundColor: Theme.bgColor,
    borderRadius: Theme.bgBorderRadius,
    padding,
    visible: component.enabled,
  }, [
    TextBlock({
      container: {
        width: percentWidth(80),
        height: 48,
        justifyContent: 'center',
      },
      text: {
        fontName: Theme.fontMainBold.name,
        content: 'Controls',
        color: Theme.textColor,
        fontSize: 48,
        letterSpacing: -0.1,
      },
    }),
    DialogButton({
      id: ButtonId.CloseButton,
      imageUrl: 'assets/ui/icons/close.png',
      absolutePosition: new Three.Vector2(width - padding - 48, padding + 4),
    }),
    isVRSession ? DialogButton({
      id: ButtonId.Sound,
      imageUrl: 'assets/ui/icons/sound.png',
      absolutePosition: new Three.Vector2(padding, padding + 4),
    }) : null,
    // to remove space by IconButton absolutePosition
    isVRSession ? Container({
      width,
      height: 0.01,
      margin: -20,
    }, []) : null,
    Container({
      width,
      height: 0.01,
      margin: -20,
    }, []),
    !isMobile && isVrSupported ? RadioSwitch({
      width: width - 2 * padding,
      height: 56,
      options: [{ id: TabsId.PC, text: 'PC' }, { id: TabsId.VR, text: 'VR' }],
    }) : null,
    ...content,
    Container({
      width: width - padding * 2,
      height: space,
    }, []),
    Button({
      id: ButtonId.StartButton,
      width: percentWidth(80),
      titleWidth: percentWidth(80),
      height: 50,
      margin: margin / 2,
      title: 'Start',
      fontName: Theme.fontMainBold.name,
      titleFontSize: 26,
      backgroundColor: '#D4FF00',
      hoverColor: '#E7FF72',
      activeColor: '#AACD00',
      fontColor: '#3D0F99',
    }),
  ]);
}
