import { FC, useMemo } from 'react';
import classnames from 'classnames';
import classNames from './Form.module.css';
import Icon from '../../../../../components/Icons/Icon';

export interface ErrorsProps {
  invalid: boolean;
  type?: string;
  fieldLength: number;
}

const Errors: FC<ErrorsProps> = (props) => {
  const {
    invalid,
    type,
    fieldLength,
  } = props;

  const colorRegexp = useMemo(() => {
    if (!invalid && fieldLength > 2) return '#D4FF00';
    if (invalid && (type === 'matches')) return '#FF764D';
    return '#FFFFFF';
  }, [invalid, fieldLength, type]);

  const colorValueLength = useMemo(() => {
    if (!invalid && fieldLength > 2) return '#D4FF00';
    if (invalid && (type === 'max' || type === 'min')) return '#FF764D';
    return '#FFFFFF';
  }, [invalid, fieldLength, type]);

  return (
    <div
      className={classNames.errors}
    >
      <div className={classNames.text}>
        <Icon
          name="bullit"
          fill={colorRegexp}
        />
        <div className={classnames(
          classNames.textMessage,
          { [classNames.textMessageSuccess]: !invalid && fieldLength > 2 },
          { [classNames.textMessageError]: invalid && (type === 'matches') },
        )}
        >
          English characters, alphanumerics, hyphens, underscores, and tildes
        </div>
      </div>
      <div className={classNames.text}>
        <Icon
          fill={colorValueLength}
          name="bullit"
        />
        <div
          className={classnames(
            classNames.textMessage,
            { [classNames.textMessageSuccess]: !invalid && fieldLength > 2 },
            { [classNames.textMessageError]: invalid && (type === 'max' || type === 'min') },
          )}
        >
          At least 3 characters, no more than 8
        </div>
      </div>
    </div>
  );
};

export default Errors;
