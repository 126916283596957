import { AbstractSpaceGenerator } from './AbstractSpaceGenerator';
import {
  AnimatedObject, AvatarAssetsType, LightStateInterface,
  SettingsType, VideoBannersType,
} from '../../assets/types';
import { settings } from '../../assets/TPHSpace/settings';
import { colliders, models } from '../../assets/TPHSpace/models';
import * as avatars from '../../assets/TPHSpace/avatarsModels';
import { videoBanners } from '../../assets/TPHSpace/videoBanners';

export default class TPHSpaceGenerator extends AbstractSpaceGenerator {
  public get settings(): SettingsType {
    return settings;
  }

  public get colliderModelUrl(): string {
    return this.getSpacePath(colliders[0]);
  }

  public get spaceModelUrl(): string {
    return this.getSpacePath(models[0]);
  }

  public get avatarsAssets(): AvatarAssetsType {
    return avatars;
  }

  public get videoBanners(): VideoBannersType | undefined {
    return videoBanners;
  }

  public get animatedObjects(): AnimatedObject[] {
    // TODO: move to assets
    return [{
      name: 'logo_arm',
      resetPosition: true,
      visible: false,
    }];
  }
}
