import * as EmptyAudio from './EmptyAudio';

export type AudioSourceType = {
  mp3: string;
};

export class AudioController {
  element: HTMLAudioElement | null = null;

  public createElement(appendTo: HTMLElement = document.body): Promise<HTMLAudioElement | null> {
    this.element = document.createElement('audio') as HTMLAudioElement;

    this.element.autoplay = false;
    this.element.controls = false;
    this.element.muted = false;
    this.element.loop = true;
    this.element.crossOrigin = 'anonymous';
    this.element.style.display = 'none';
    this.element.src = EmptyAudio.mpeg;
    appendTo.appendChild(this.element);
    return this.element.play().then(() => {
      if (this.element) this.element.src = '';
      return this.element;
    });
  }

  public destroy() {
    this.element?.remove();
  }

  public isPlaying(): boolean {
    if (!this.element) return false;
    return this.element.currentTime > 0
      && !this.element.paused && !this.element.ended
      && this.element.readyState >= this.element.HAVE_CURRENT_DATA;
  }

  public toggle(isPlaying: boolean): Promise<void> {
    if (!this.element) return Promise.resolve();
    if (isPlaying) {
      return this.element.play().then(() => {
        this.unmute();
      });
    }
    if (!isPlaying) {
      this.mute();
      this.element.pause();
    }
    return Promise.resolve();
  }

  public get time(): number {
    if (!this.element) return 0;
    return this.element.currentTime;
  }

  public get duration(): number {
    if (!this.element) return 0;
    return this.element.duration;
  }

  public setTime(time: number) {
    if (!this.element) return 0;
    this.element.currentTime = time;
  }

  public setSource({ mp3 }: AudioSourceType) {
    if (!this.element) return Promise.resolve();
    this.element.pause();
    this.element.currentTime = 0;
    if (this.element.canPlayType('audio/mp3')) {
      this.element.src = mp3;
    }
    return Promise.resolve();
    // return this.update();
  }

  public update() {
    if (!this.element) return;
    if (this.isPlaying()) this.element.pause();
    return this.element.play().then(
      () => {
        this.element?.pause();
      },
    );
  }

  public setVolume(value: number) {
    if (!this.element) return;
    this.element.volume = value;
  }

  public mute() {
    if (!this.element) return;
    this.element.muted = true;
  }

  public unmute() {
    if (!this.element) return;
    this.element.muted = false;
  }

  public toggleMute() {
    if (!this.element) return;
    this.element.muted = !this.element.muted;
  }

  public get isMuted() {
    if (!this.element) return true;
    return this.element.muted;
  }
}
