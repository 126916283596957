import { SettingsType } from '../types';

export const settings: SettingsType = {
  baseAssetsUrl: 'assets/scene/classroomSpace/',
  title: 'Classroom Space',
  background: {
    desktop: 'assets/ui/background/ClassroomDesktop.png',
    mobile: 'assets/ui/background/ClassroomMobile.png',
  },
};
