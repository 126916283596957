import { SettingsType } from '../types';

export const settings: SettingsType = {
  baseAssetsUrl: 'assets/scene/vickySpace/',
  title: 'Vicky Space',
  background: {
    desktop: 'assets/ui/background/VickysSpace.png',
    mobile: 'assets/ui/background/VickySpaceMobile.png',
  },
};
