import React, { FC, useCallback } from 'react';
import classnames from 'classnames';
import PoweredOwnImage from '../../assets/Loader/ownverseLogo.png';
import PoweredOwnImageSecondary from '../../assets/Loader/ownverseLogoSecondary.png';
import classNames from './PoweredOwn.module.css';
import { OWNVERSE } from '../../../domain/constants/redirectPages';
import { Adaptive } from '../../../domain/ui/adaptive';

export interface PoweredOwnProps {
  loading: boolean;
  show: boolean;
}

const PoweredOwn: FC<PoweredOwnProps> = (props) => {
  const { loading, show } = props;

  const device = Adaptive.isTouchDevice() ? 'Mobile' : 'Desktop';

  const redirectLandingPageHandler = useCallback(() => {
    window.open(OWNVERSE, '_blank');
  }, []);

  return (
    <div
      onClick={redirectLandingPageHandler}
      className={classnames(
        classNames.powered,
        { [classNames.poweredTop]: !loading },
        { [classNames[`powered${device}`]]: !loading },
        { [classNames.poweredTablet]: !loading },
      )}
    >
      <div className={classnames(
        classNames.text,
        classNames.text_desktop,
        { [classNames.textDarkOrWhite]: !loading },
      )}
      >
        Powered
        <div>
          by&nbsp;
          <span className={classNames.powered_name}>OWNverse</span>
        </div>
      </div>
      <div className={classnames(classNames.text, classNames.text_mobile, { [classNames.textDarkOrWhite]: !loading })}>
        Powered
        by&nbsp;
        <span className={classNames.powered_name}>OWNverse</span>
      </div>
      <img
        className={classnames({ [classNames.image]: !loading })}
        alt="own"
        src={loading ? PoweredOwnImage : PoweredOwnImageSecondary}
      />
    </div>
  );
};

export default PoweredOwn;
