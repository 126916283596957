import {
  defaultAvatarAnimations,
  defaultAvatarsList, defaultAvatarsMenu,
  defaultAvatarsModels,
  defaultAvatarWalkDuration,
} from '../DefaultAvatars';

// TODO: clone default values

export const avatarsMenu = [
  { id: 'Avatar_vicky', imgUrl: 'assets/ui/avatars/vicky.png' },
  ...defaultAvatarsMenu,
];

export const avatars = {
  ...defaultAvatarsList,
  vicky: {
    model: 'vicky',
    animations: 'mira',
  },
};

export const defaultAvatar = 'vicky';

export const avatarsModels = {
  ...defaultAvatarsModels,
  vicky: 'assets/avatars/VRM/vicky_vrm.vrm',
};

export const avatarWalkDuration = defaultAvatarWalkDuration;

export const avatarAnimations = defaultAvatarAnimations;
