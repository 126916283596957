import { PlacesId } from '../enum/PlacesId';
import { ScenePlaceId } from './scenePlaceId';
import { PlaceMenuItem } from '../../services/SpaceZoneGenerator/AbstractSpaceGenerator';

export const placeMenuItems: PlaceMenuItem[] = [
  {
    id: PlacesId.Shopify,
    scenePlaceId: ScenePlaceId.SHOPIFY,
    text: 'Shopify',
    imgUrl: 'assets/ui/icons/shopify.png',
    sortOrder: 10,
  },
  {
    id: PlacesId.Youtube,
    scenePlaceId: ScenePlaceId.YOUTUBE,
    text: 'Youtube',
    imgUrl: 'assets/ui/icons/youtube.png',
    sortOrder: 20,
  },
  {
    id: PlacesId.Artstation,
    scenePlaceId: ScenePlaceId.ARTSTATION,
    text: 'Artstation',
    imgUrl: 'assets/ui/icons/artstation.png',
    sortOrder: 30,
  },
  {
    id: PlacesId.Spotify,
    scenePlaceId: ScenePlaceId.SPOTIFY,
    text: 'Spotify',
    imgUrl: 'assets/ui/icons/spotify.png',
    sortOrder: 40,
  },
  {
    id: PlacesId.Instagram,
    scenePlaceId: ScenePlaceId.INSTAGRAM,
    text: 'Instagram',
    imgUrl: 'assets/ui/icons/Instagram.png',
    sortOrder: 50,
  },
  {
    id: PlacesId.Behance,
    scenePlaceId: ScenePlaceId.BEHANCE,
    text: 'Behance',
    imgUrl: 'assets/ui/icons/behance.png',
    sortOrder: 60,
  },
  {
    id: PlacesId.SketchFab,
    scenePlaceId: ScenePlaceId.SKETCHFAB,
    text: 'SketchFab',
    imgUrl: 'assets/ui/icons/sketchfab.png',
    sortOrder: 70,
  },
  {
    id: PlacesId.Canva,
    scenePlaceId: ScenePlaceId.CANVA,
    text: 'Canva',
    imgUrl: 'assets/ui/icons/canva.png',
    sortOrder: 80,
  },
  {
    id: PlacesId.Mirror,
    scenePlaceId: ScenePlaceId.MIRROR,
    text: 'Mirror',
    imgUrl: 'assets/ui/icons/mirror.png',
    sortOrder: 90,
  },
  {
    id: PlacesId.Pinterest,
    scenePlaceId: ScenePlaceId.PINTEREST,
    text: 'Pinterest',
    imgUrl: 'assets/ui/icons/pinterest.png',
    sortOrder: 100,
  },
];
