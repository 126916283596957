/**
 * List of teleportation places existing on scene
 */
export enum ScenePlaceId {
  YOUTUBE = 'youtube',
  MIRROR = 'mirror',
  SHOPIFY = 'shopify',
  ARTSTATION = 'artstation',
  BEHANCE = 'behance',
  CANVA = 'canva',
  SKETCHFAB = 'sketchFab',
  SPOTIFY = 'spotify',
  PINTEREST = 'pinterest',
  INSTAGRAM = 'instagram',
}
