import { LightObjectsInterface, LightStateInterface } from '../types';

export const allLightObjects: LightObjectsInterface = [
  'env_day_sphere',
  'env_night_sphere',
];

export const lightStates: LightStateInterface = {
  night: {
    lightMaps: {
      floor: {
        path: 'lightMaps/floor_light.jpg',
        intensity: 1.2,
        envIntensity: 0,
      },
    },
    lightObjects: [
      'env_night_sphere',
    ],
  },
  day: {
    lightMaps: {
      floor: {
        path: 'lightMaps/floor_light.jpg',
        intensity: 1.2,
        envIntensity: 0,
      },
    },
    lightObjects: [
      'env_day_sphere',
    ],
  },
};
