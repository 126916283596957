import { LightObjectsInterface, LightStateInterface} from '../types';

export const allLightObjects: LightObjectsInterface = [
  'day_background',
  'day_blue_emission',
  'day_chair',
  'day_floor',
  'day_module_01',
  'day_module_02',
  'day_module_logo',
  'day_podium',
  'day_podium_chair',
  'day_set_furniture',
  'day_tv_screen_logo',
  'night_background',
  'night_chair',
  'night_floor',
  'night_module_01',
  'night_module_02',
  'night_module_logo',
  'night_podium_chair',
  'night_podium_stage',
  'night_red_emission',
  'night_set_furniture',
  'night_tv_screen_logo',
];

export const lightStates: LightStateInterface = {
  night: {
    lightMaps: {
      night_chair: {
        path: 'lightMaps/night/night_chair_lm.jpg',
        intensity: 1,
        envIntensity: 0,
      },
      night_floor: {
        path: 'lightMaps/night/night_floor_lm.jpg',
        intensity: 1,
        envIntensity: 0,
      },
      night_module_01: {
        path: 'lightMaps/night/night_module_01_lm.jpg',
        intensity: 1,
        envIntensity: 0,
      },
      night_module_02: {
        path: 'lightMaps/night/night_module_02_lm.jpg',
        intensity: 1,
        envIntensity: 0,
      },
      night_module_logo: {
        path: 'lightMaps/night/night_module_logo_lm.jpg',
        intensity: 1,
        envIntensity: 0,
      },
      night_podium_chair: {
        path: 'lightMaps/night/night_podium_chair_lm.jpg',
        intensity: 1,
        envIntensity: 0,
      },
      night_podium: {
        path: 'lightMaps/night/night_podium_lm.jpg',
        intensity: 1,
        envIntensity: 0,
      },
      night_set_furniture: {
        path: 'lightMaps/night/night_set_furniture_lm.jpg',
        intensity: 1,
        envIntensity: 0,
      },
    },
    lightObjects: [
      'night_background',
      'night_chair',
      'night_floor',
      'night_module_01',
      'night_module_02',
      'night_module_logo',
      'night_podium_chair',
      'night_podium_stage',
      'night_red_emission',
      'night_set_furniture',
      'night_tv_screen_logo',
    ],
  },
  day: {
    lightMaps: {
      day_chair: {
        path: 'lightMaps/day/day_chair_lm.jpg',
        intensity: 1,
        envIntensity: 0,
      },
      day_floor: {
        path: 'lightMaps/day/day_floor_lm.jpg',
        intensity: 1,
        envIntensity: 0,
      },
      day_module_01: {
        path: 'lightMaps/day/day_module_01_lm.jpg',
        intensity: 1,
        envIntensity: 0,
      },
      day_module_02: {
        path: 'lightMaps/day/day_module_02_lm.jpg',
        intensity: 1,
        envIntensity: 0,
      },
      day_module_logo: {
        path: 'lightMaps/day/day_module_logo_lm.jpg',
        intensity: 1,
        envIntensity: 0,
      },
      day_podium_chair: {
        path: 'lightMaps/day/day_podium_chair_lm.jpg',
        intensity: 1,
        envIntensity: 0,
      },
      day_podium: {
        path: 'lightMaps/day/day_podium_lm.jpg',
        intensity: 1,
        envIntensity: 0,
      },
      day_set_furniture: {
        path: 'lightMaps/day/day_set_furniture_lm.jpg',
        intensity: 1,
        envIntensity: 0,
      },
    },
    lightObjects: [
      'day_background',
      'day_blue_emission',
      'day_chair',
      'day_floor',
      'day_module_01',
      'day_module_02',
      'day_module_logo',
      'day_podium',
      'day_podium_chair',
      'day_set_furniture',
      'day_tv_screen_logo',
    ],
  },
};
