import { AudioPlacesType } from '../types';

export const AudioPlaces: AudioPlacesType = {
  djset: {
    tracks: [
      {
        title: 'Rexlambo - incident',
        previewUrl: 'audio/track_1_pre.jpg',
        mp3: 'audio/track_1.mp3',
      },
      {
        title: 'Mythic - RATHER BE',
        previewUrl: 'audio/track_2_pre.jpg',
        mp3: 'audio/track_2.mp3',
      },
      {
        title: 'C.H.A.Y. - Fast and Furious Tokyo Drift',
        previewUrl: 'audio/track_3_pre.jpg',
        mp3: 'audio/track_3.mp3',
      },
      {
        title: 'Ghostrifter - Merry Bay',
        previewUrl: 'audio/track_4_pre.jpg',
        mp3: 'audio/track_4.mp3',
      },
      {
        title: 'SnoreStar - Princess',
        previewUrl: 'audio/track_5_pre.jpg',
        mp3: 'audio/track_5.mp3',
      },
    ],
  },
};
