import {
  defaultAvatarAnimations,
  defaultAvatarName,
  defaultAvatarsList, defaultAvatarsMenu,
  defaultAvatarsModels,
  defaultAvatarWalkDuration,
} from '../DefaultAvatars';

// TODO: clone default values

export const avatarsMenu = defaultAvatarsMenu;
export const avatars = defaultAvatarsList;

export const defaultAvatar = defaultAvatarName;

export const avatarsModels = defaultAvatarsModels;

export const avatarWalkDuration = defaultAvatarWalkDuration;

export const avatarAnimations = defaultAvatarAnimations;
