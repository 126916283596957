import TopMenuComponent from '../../components/UI/TopMenu.component';
import { Container } from '../elements';
import { MainButton } from '../elements/Buttons/MainButton';
import { ButtonId } from '../enum/ButtonId';
import { ActionButton } from '../elements/Buttons/ActionButton';
import { Adaptive, TopMenuAdaptive } from '../adaptive';

export function TopMenuTemplate(component: TopMenuComponent, adaptive: Adaptive) {
  const {
    padding,
    buttonSize,
    isVRSession,
  } = adaptive as TopMenuAdaptive;
  const { width, height } = adaptive;
  if (isVRSession) return null;
  return Container({
    name: 'TopMenu',
    width,
    height,
    // backgroundOpacity: 1,
    // backgroundColor: '#FFFFFF',
    padding,
    contentDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
  }, [
    ActionButton({
      id: ButtonId.MicButton,
      imageUrl: 'assets/ui/icons/mic-blue.png',
      altImageUrl: 'assets/ui/icons/no-mic-blue.png',
      width: buttonSize,
      height: buttonSize,
    }),
    ActionButton({
      id: ButtonId.CamButton,
      imageUrl: 'assets/ui/icons/cam-blue.png',
      altImageUrl: 'assets/ui/icons/no-cam-blue.png',
      width: buttonSize,
      height: buttonSize,
    }),
    ActionButton({
      id: ButtonId.Sound,
      imageUrl: 'assets/ui/icons/sound-blue.png',
      altImageUrl: 'assets/ui/icons/no-sound-blue.png',
      width: buttonSize,
      height: buttonSize,
    }),
    // ActionButton({ id: ButtonId.InfoButton, imageUrl: 'assets/ui/icons/info_light.png' }),
    ActionButton({
      id: ButtonId.DayNightButton,
      imageUrl: 'assets/ui/icons/day.png',
      altImageUrl: 'assets/ui/icons/night.png',
      width: buttonSize,
      height: buttonSize,
    }),
    MainButton({
      id: ButtonId.MainButton,
      imageUrl: 'assets/ui/icons/logo.png',
      width: buttonSize,
      height: buttonSize,
    }),
  ]);
}
