import {
  defaultAvatarAnimations,
  defaultAvatarName,
  defaultAvatarsList, defaultAvatarsMenu,
  defaultAvatarsModels,
  defaultAvatarWalkDuration,
} from '../DefaultAvatars';
import { AvatarList, AvatarsMenuListType, AvatarsModels } from '../types';

// TODO: clone default values

export const avatarsMenu: AvatarsMenuListType = [
  { id: 'Avatar_martian', imgUrl: 'assets/ui/avatars/martian.png' },
  { id: 'Avatar_roughneck', imgUrl: 'assets/ui/avatars/roughneck.png' },
];
export const avatars: AvatarList = {
  martian: {
    model: 'martian',
    animations: 'yuki',
  },
  roughneck: {
    model: 'roughneck',
    animations: 'yuki',
  },
};

export const defaultAvatar = 'martian';

export const avatarsModels: AvatarsModels = {
  martian: 'assets/avatars/VRM/martian_vrm.vrm',
  roughneck: 'assets/avatars/VRM/roughneck_vrm.vrm',
};

export const avatarWalkDuration = defaultAvatarWalkDuration;

export const avatarAnimations = defaultAvatarAnimations;
